.image {
  height: 40vh;
  width: 100%;
  background-image: url("../../../../../statics/images/screening-entity.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.body {
  padding: 20px 40px 20px 40px;
}

.body1 {
  padding: 20px 40px 150px 40px;
}
.body h2 {
  text-align: center;
}

.body .message {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
  padding: 10px 25px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.body .message .traficLightBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.body .message .traficLightBox.red {
  background-color: #ff6b6b;
}

.body .message .traficLightBox.yellow {
  background-color: #f7b740;
}
.body .message .traficLightBox.green {
  background-color: #1dd1a1;
}

.body .message img {
  width: 64px;
  margin-right: 20px;
}

.body .message p {
  margin: 0;
  font-size: 18px;
}

.arrow {
  display: flex;
  align-items: center;
}

.arrow svg {
  margin-top: -3px;
}

.row {
  display: flex;
  align-items: center;
}

.row .traficLight {
  width: 94px;
  margin-right: 30px;
}
