@import "../../lib/styles/base";

.ds-chat-message {
  position: relative;
  font-size: 13px;
  padding: 16px;
  border-radius: 16px 16px 0 16px;
  background-color: $grayscale-200;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 16px solid $grayscale-200;
    border-left: 16px solid transparent;
    bottom: -16px;
    right: 0;
  }
}
