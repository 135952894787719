.image {
  height: 40vh;
  width: 100%;
  background-image: url("../../../../../statics/images/screening-end-user.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.body {
  padding: 50px 40px 150px 40px;
}

.body h2 {
  text-align: center;
}

.body .message {
  display: flex;
  align-items: center;
  margin-top: 100px;
  position: relative;
  padding: 10px 25px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.body .message .traficLightBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.body .message .traficLightBox.red {
  background-color: #ff6b6b;
}

.body .message .traficLightBox.yellow {
  background-color: #f7b740;
}
.body .message .traficLightBox.green {
  background-color: #1dd1a1;
}

.body .message img {
  width: 64px;
  margin-right: 20px;
}

.body .message p {
  margin: 0;
  font-size: 18px;
}
