.ds-toolbar {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  min-height: 56px;
  @include media-breakpoint-up(xs) {
    // and if landscape orientation
    min-height: 48px;
  }
  @include media-breakpoint-down(sm) {
    min-height: 64px;
  }
}
