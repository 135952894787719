.ds-header-app {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 60px;
  justify-content: space-between;
  background: $header-bg;

  &.ds-header-app__menu-mobile--open {
    .ds-header-app__logo {
      visibility: visible;
      display: inline-block;
    }

    .ds-menu-expand {
      @extend .ds--show;
    }

    .ds-header-app__name {
      opacity: 0;
    }

    .ds-header-app__panel {
      visibility: visible;
      pointer-events: auto;
      height: calc(100vh - 60px);
      transition:
        height 0.35s ease-out,
        visibility 0.4s ease-out;
    }

    .ds-header-app__menu-mobile {
      margin-right: 5px;

      &::after {
        opacity: 0;
      }

      span {
        &::before {
          transform: rotate(45deg);
          box-shadow: none;
        }

        &::after {
          transform: rotate(135deg);
          background: $white;
        }
      }
    }

    .ds-header-app__actions {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all 0.35s ease-in;
    }
  }

  @include media-breakpoint-up(md) {
    position: inherit;
    &.ds-header-app--sticky {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}

//  Logo
// -------------------------------------------------------------------

.ds-header-app__brand {
  display: flex;
}

.ds-header-app__logo {
  display: none;
  padding: 0 $header-spacing-medium 0 $header-spacing-large;

  img {
    display: block;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    visibility: visible;
    display: inline-block;
    padding: 0 $header-spacing-medium;
  }
}

.ds-header-app__name {
  opacity: 1;
  position: relative;
  padding: $header-spacing-medium;
  font-size: 15px;
  line-height: 26px;
  color: $header-name;
  background: linear-gradient(
    to right,
    rgba($color-primary-1, 0.65) 0%,
    rgba($header-bg, 0.65) 90%
  );
}

//  Navigation
// -------------------------------------------------------------------

.ds-header-app__navigation {
  display: flex;
  align-items: center;
  padding: $header-spacing-medium;
  line-height: 1;
  background: primary-color(color-2, 700);
  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.ds-header-app__menu-mobile {
  display: block;
  position: relative;
  padding: 0;
  margin-left: $header-spacing-xsmall;
  font-size: 24px;
  color: $white;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 1px;
    background: rgba($white, 0.25);
    pointer-events: none;
  }

  span {
    position: relative;
    display: block;
    overflow: hidden;
    height: 24px;
    width: 20px;
    margin-left: $header-spacing-medium;
    text-indent: -999em;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 12px;
      display: block;
      width: 20px;
      height: 2px;
      transition: transform 0.2s ease-in;
    }

    &::before {
      background: $white;
      box-shadow:
        0 6px 0 $white,
        0 -6px 0 $white;
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.ds-header-app__actions {
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 22px;
  right: 58px;
  padding: 0 0 0 $header-spacing-regular;
  transition: all 0.35s ease-in 0.2s;

  button {
    padding: 0;
    margin: 0 $header-spacing-xsmall;
    line-height: 1;
    font-size: 18px;
    color: $white;
  }

  @include media-breakpoint-up(md) {
    position: inherit;
    top: auto;
    right: auto;
  }
}

.ds-header-app__panel {
  visibility: hidden;
  position: fixed;
  top: 60px;
  width: 100%;
  right: 0;
  left: 0;
  padding: 30px 25px 25px;
  background: $primary-color-2-700;
  height: 0;
  pointer-events: none;
  transition:
    opacity 0.25s ease-in,
    height 0.35s ease-out,
    visibility 0.4s ease-out;

  li {
    list-style: none;
  }

  a {
    display: block;
    color: $white;
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    visibility: visible;
    position: inherit;
    top: auto;
    height: auto;
    padding: 0;
    pointer-events: auto;
    transform: none;
  }
}

.ds-header-app__menu {
  padding: 0;
  margin: 0;
  //border-top: 1px solid rgba($white, .25);
  border-bottom: 1px solid rgba($white, 0.25);

  li {
    //border-top: 1px solid rgba($white, .25);

    &:first-child {
      border-bottom: 0;
    }
  }

  a {
    padding: $header-spacing-large 0;
    font-weight: 700;
    transition: color 0.25s;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    border: 0;
    & > li {
      display: inline-block;
      position: relative;
      border: 0;
      margin: 0 $header-spacing-medium;
      font-size: 13px;

      & > .ds-header-app__submenu {
        // opacity: 0;
        // visibility: hidden;
        position: absolute;
        top: 36px;
        left: -43px;
        min-width: 230px;
        padding: 0;
        background: $primary-color-2-700;
      }
    }
    .ds-header-app__item--active {
      & > .ds-header-app__submenu {
        & > li {
          position: relative;

          &.ds-show > .ds-header-app__submenu__subitems {
            display: block;
          }

          &.ds-show:after {
            transform: rotate(180deg);
          }

          & > .ds-header-app__submenu__subitems {
            display: none;
            padding: 0;

            & li {
              position: relative;

              &:not(:last-child):after {
                content: "";
                width: 80%;
                height: 1px;
                background-color: rgba($white, 0.25);
                position: absolute;
                bottom: 0;
                left: 10%;
              }

              & a {
                color: $primary-color-3-300;
                display: block;
                padding: 16px 0;
                margin: 0 30px;
                border: 0;
                transition: transform 0.3s ease-out;
                min-height: 56px;
                line-height: 23px;
                position: relative;

                &:hover {
                  color: $white;
                }
              }
            }
          }

          &:hover {
            background-color: $primary-color-2-600;
          }

          &:not(:last-child):before {
            content: "";
            width: 80%;
            height: 1px;
            background-color: rgba($white, 0.25);
            position: absolute;
            bottom: 0;
            left: 10%;
          }

          &:after {
            content: "";
            will-change: transform;
            transition: transform 0.2s ease-out;
            background-repeat: no-repeat;
            background-image: svg-uri(
              '<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#647592" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
            );
            width: 14px;
            height: 9px;

            .ds-show & {
              transform: rotate(180deg);
            }

            color: $primary-color-3-300;
            position: absolute;
            right: 16px;
            top: 24px;
          }

          &:hover a {
            color: $white;
            transform: translate(5px, 0);
          }

          &:hover:after {
            background-image: svg-uri(
              '<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#FFF" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
            );
          }

          &:hover span {
            color: $white;
          }

          & a {
            color: $primary-color-3-300;
            display: block;
            padding: 16px 0;
            margin: 0 30px;
            border: 0;
            transition: transform 0.3s ease-out;
            min-height: 56px;
            line-height: 23px;
            position: relative;
          }
        }
      }

      a {
        color: $white;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -24px;
        left: 0;
        height: 4px;
        background: $color-primary-1;
      }
    }

    a {
      padding: 0;
      font-weight: 500;
      color: $primary-color-3-300;
    }
  }
}

.ds-header-app__submenu {
  padding: 0;
  margin: 0;

  a {
    display: block;
    font-weight: 400;
  }

  .ds-header-app__submenu {
    a {
      padding-left: $header-spacing-xlarge;
    }
  }

  @include media-breakpoint-up(md) {
    border: 0;
    li {
      &:last-child {
        border-bottom: 0;
      }
    }
    a {
      padding: $header-spacing-medium $header-spacing-medium
        $header-spacing-medium $header-spacing-large;
    }
  }
}

.ds-header-app__user {
  margin-top: $header-spacing-large;

  .ds-menu-expand {
    position: inherit;
    background: transparent;
    box-shadow: none;
    padding: 0;

    a,
    button {
      padding: $header-spacing-regular 0;

      &:hover,
      &:focus {
        background: none;
      }
    }
  }

  .ds-menu-expand__item {
    [class^="ds-icon"] {
      position: relative;
      top: 2px;
    }
  }

  .ds-menu-expand__inner {
    &::before,
    &::after {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;
    margin: 0 $header-spacing-medium;
    padding-left: $header-spacing-medium;
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      height: 24px;
      width: 1px;
      background: rgba($white, 0.25);
    }
    .ds-menu-expand {
      position: absolute;
      top: 27px;
      right: -7px;
      min-width: 160px;
      padding: $header-spacing-base 0;
      background: #fff;
      box-shadow: 0 2px 10px rgba($grayscale-700, 0.35);

      a,
      button {
        padding: $header-spacing-xsmall $header-spacing-medium;
        color: $grayscale-600;

        &:hover,
        &:focus {
          background: $grayscale-200;
        }
      }
    }
    .ds-menu-expand__inner {
      &::before,
      &::after {
        display: block;
        right: 12px;
        left: auto;
      }
    }
  }
}

.ds-header-app__user-button {
  padding: 0;
  margin: 0 0 $header-spacing-regular;
  font-size: 14px;
  color: $white;
  @include media-breakpoint-up(md) {
    position: relative;
    margin: 0;
    padding-right: $header-spacing-large;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: primary-color(color-3, 300);
    transition: color 0.25s;
    &::after {
      // @include ds-icon-styles;
      // content: ds-icon-char(chevron_down);
      position: absolute;
      top: -2px;
      right: 0;
      font-size: 16px;
    }
    &:hover,
    &:focus {
      color: $white;
    }
  }
}
