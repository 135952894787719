.ds-clear-icon {
  vertical-align: middle;
}

.ds-search-box {
  padding-right: $spacing-xl !important;
  &:not(:valid) ~ .ds-clear-button {
    visibility: hidden;
  }
}

.ds-clear-button {
  &:focus {
    outline: 0;
  }
}

.ds-form--dark {
  .ds-label,
  .ds-form-helptext,
  .ds-clear-button {
    color: $white;
  }

  [type="search"] {
    // &.ds-field--dark {
    color: $white;
    border-color: $white;
    background-color: $input-bg-dark;
    background-image: svg-uri(
      '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>Icons / Magnifying Glass</title><path class="st0" fill="none" d="M-2-2h20v20H-2z"/><path class="st1" fill="#ffffff" d="M10.9 9.7h-.7l-.2-.3c.8-.9 1.3-2.2 1.3-3.5 0-3-2.4-5.4-5.4-5.4S.5 2.9.5 5.9c0 3 2.4 5.4 5.4 5.4 1.3 0 2.6-.5 3.5-1.3l.2.2v.7l4.2 4.2 1.2-1.2-4.1-4.2zm-5 0C3.8 9.7 2.1 8 2.1 5.9s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8S8 9.7 5.9 9.7z"/></svg>'
    );
    // }
  }
}
