.row {
  display: flex;
  align-items: center;
}

.row .input {
  position: relative;
  width: 100%;
  height: 40px;
  max-width: 250px;
}

.row .input input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.row button,
.row .fakeButton {
  margin-left: 15px;
}

.row .fakeButton {
  background-color: #0085ad;
  padding: 0 38px;
  height: 41px;
  border-radius: 5px;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
}

.row .fakeButton.fail {
  background-color: #ff6b6b;
}

.row .fakeButton.success {
  background-color: #1dd1a1;
}

.row .fakeButton .loading svg {
  stroke: #ffffff;
  margin-top: -5px;
}

.row .fakeButton .icon {
  height: 24px;
}

.row .fakeButton .icon svg {
  fill: #ffffff;
}

.row .input .loader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.loader div {
  height: 3px;
  background: #0085ad;
}
