.ds-menu-expand {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  padding: $overflow-menu-padding-regular 0 $overflow-menu-padding-small;
  min-width: 120px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  box-shadow: 0 2px 10px rgba($grayscale-700, 0.35);
  &.ds-menu-expand--overflow-menu {
    padding: 0;
    .ds-menu-expand__inner {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -8px;
        left: auto;
        transform: translate(0, -50%);
      }
      &::before {
        @include triangle(10px, $grayscale-700, right);
        opacity: 0.05;
        top: 50%;
        right: -10px;
      }
      &::after {
        @include triangle(8px, $white, right);
        top: 50%;
      }
      hr {
        margin: 0 16px -1px;
        background-color: $overflow-menu-hr-bg;
      }
    }
    .ds-menu-expand__item {
      button,
      a {
        position: relative;
        padding: $overflow-menu-padding-regular $overflow-menu-padding-medium
          $overflow-menu-padding-regular $overflow-menu-padding-xlarge;
        font-size: 14px;
        color: $overflow-menu-item-color;
        &:hover,
        &:focus {
          background: $overflow-menu-item-color-hover;
        }
      }
      [class^="ds-icon"] {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
      }
    }
  }
}

.ds-menu-expand__inner {
  @extend .ds-picklist;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 36px;
  }
  // &::before {
  //   @include triangle(10px, $grayscale-700, top);
  //   opacity: 0.05;
  //   top: -21px;
  // }
  // &::after {
  //   @include triangle(8px, $white, top);
  //   top: -18px;
  // }
  hr {
    margin: 0;
    height: 1px;
    border: none;
    background-color: $grayscale-200;
  }
}

.ds-menu-expand__list {
  padding: 0;
  margin: 0;
}

.ds-menu-expand__item {
  list-style: none;
  color: $gray-600;
  a,
  button {
    display: block;
    width: 100%;
    padding: $overflow-menu-padding-small $overflow-menu-padding-large;
    color: $gray-600;
    text-align: left;
    text-decoration: none;
    &:hover,
    &:focus {
      background: $grayscale-200;
    }
  }
  button {
    padding-left: $overflow-menu-padding-xlarge;
  }
  &.ds-menu-expand__item--active {
    background: $grayscale-200;
  }
  &.ds-menu-expand__item--active {
    position: relative;
    &::before {
      // @include ds-icon-styles;
      // content: ds-icon-char(check);
      position: absolute;
      top: 3px;
      left: 8px;
      color: $grayscale-600;
    }
  }
}

.ds-overflow-menu__button {
  [class^="ds-icon"] {
    font-size: 22px;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Menu expand
//
// Toggle contextual overlays for displaying lists of links and action buttons.
//
// Style guide: MenuExpand

// Normal
//
// Menu expand is a container with a list of items.
// Use the `<a>` tag to link to another page, or an anchor within a page.
//
// Markup: ../../templates/partials/components/menu-expand/menu-expand.hbs
//
// Weight: -10
//
// Style guide: MenuExpand.Normal

// With line
//
// Use `<hr>` element to separate items and create section in the list.
//
// Markup: ../../templates/partials/components/menu-expand/menu-expand-line.hbs
//
// Weight: -9
//
// Style guide: MenuExpand.Line

// Filter
//
// Use the `<button>` tag to performs an action that changes something on the current page.
//
// Markup: ../../templates/partials/components/menu-expand/menu-expand-filter.hbs
//
// Weight: -8
//
// Style guide: MenuExpand.Filter
