table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 0;
  thead {
    font-weight: 500;
    vertical-align: middle;
    background: $table-thead-bg;
  }
  tbody {
    font-weight: 400;
    background: $table-tbody-bg;
    tr {
      &:nth-child(odd) {
        border-bottom: 0;
        background-color: rgba($table-tr-odd-bg, 0.35);
      }
      &.ds-table__row--selected:nth-child(odd),
      &.ds-table__row--selected {
        background-color: rgba($table-row-selected-color, 0.35);
      }
    }
  }
  th,
  td {
    font-size: 14px;
    text-align: left;
    color: $table-td-color;
    .ds-checkbox {
      top: -7px;
      margin: 0 auto;
      label {
        padding-left: 0;
      }
    }
  }
  th {
    font-weight: 500;
    padding: $spacing-rg $spacing-md;
    vertical-align: baseline;
    color: $table-th-color;
    &.ds-table__checkbox {
      vertical-align: middle;
    }
  }
  td {
    padding: $spacing-rg $spacing-md;
    &.ds-table__cell--enhance {
      color: $table-td-color-enhance;
    }
    &.ds-table__cell--gray {
      color: $table-td-color-gray;
    }
    &.ds-table__cell--editable {
      padding: 7px 10px;
      .ds-form-group {
        margin-bottom: 0px;
      }
    }
  }
}
.ds-table-wrapper {
  overflow-x: auto;
  &--responsive {
    overflo-x: visible;
    @include media-breakpoint-down(sm) {
      tr,
      td,
      th,
      thead,
      tbody,
      table {
        display: block;
      }
      table {
        display: flex;
        overflow: hidden;
        border-spacing: 0;
        border-collapse: collapse;
        thead {
          min-width: 100px;
          visibility: hidden;
        }
        tbody {
          flex-grow: 1;
          tr {
            position: relative;
            padding: $spacing-md;
            &:nth-child(odd) {
              &:before {
                content: "";
                background-color: rgba($table-tr-odd-bg, 0.35);
                position: absolute;
                height: 100%;
                width: 100px;
                left: -100px;
                top: 0;
              }
            }
            td {
              margin-bottom: $spacing-rg;
              margin-left: $spacing-xl;
              padding: 0;
              &:last-child {
                margin-bottom: 0;
              }
              &:before {
                content: attr(data-name);
                position: absolute;
                right: 100%;
                font-size: 14px;
                font-weight: 500;
                vertical-align: baseline;
                color: $table-th-color;
                text-align: right;
                min-width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
.ds-table__checkbox {
  width: 32px;
  padding-right: 0;
  padding-left: $spacing-xxs;
  text-align: center;
  white-space: nowrap;
}

.ds-table__button-sorting {
  position: relative;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  white-space: nowrap;
  &.ds-table__button-sorting--asc {
    .ds-icon-caret {
      transform: rotate(180deg);
    }
  }
  span {
    position: relative;
    top: 4px;
    &::before {
      font-size: 18px;
      color: $table-th-sorting;
    }
  }
}

//  Table like (table with <div>)
// -------------------------------------------------------------------

.ds-table-like {
  display: table;
  @extend table;
}

.ds-table-like__thead {
  display: table-header-group;
  @extend thead;
  .ds-table-like__cell {
    @extend th;
  }
}

.ds-table-like__tbody {
  display: table-row-group;
  @extend tbody;
}

.ds-table-like__row {
  display: table-row;
  @extend tr;
}

.ds-table-like__cell {
  display: table-cell;
  @extend td;
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Table
//
// Tables serve to provide structured display of information and vary in type from simple to very complex.
//
//
// Style guide: Table

// Normal
//
// Using the most basic table markup, here’s how `<table>` look in the design system.
//
// Markup: ../../templates/partials/base/table/table.hbs
//
// Weight: -10
//
// Style guide: Table.Normal

// Selected row
//
// Use `.ds-table__row--selected` on `<tr>` when a row is selectable.
//
// Markup: ../../templates/partials/base/table/table-selected-row.hbs
//
// Weight: -9
//
// Style guide: Table.Selected

// Table Responsive
//
// * Add modifier class `.ds-table-wrapper--responsive"` on `div.ds-table-wrapper` to enable in mobile view the transformation of row in card
//
// Markup: ../../templates/partials/base/table/table-responsive.hbs
//
// Weight: -8
//
// Style guide: Table.Table-Responsive

// Table like
//
// If it looks,works and sounds like a table, it must be a table? Yes…but no. In some case (need of flexibility or performance), use `<div>` element with `'display: table'` properties can be a better choice. Here's a list of classes needed to have an element look like a `table` element:
// * `<table>` → `.ds-table-like`
// * `<thead>` → `.ds-table-like__thead`
// * `<tbody>` → `.ds-table-like__tbody`
// * `<tfoot>` → `.ds-table-like__tfoot`
// * `<tr>` → `.ds-table-like__row`
// * `<th>` or `<td>` → `.ds-table-like__cell`
//
// Markup: ../../templates/partials/base/table/table-like.hbs
//
// Weight: -7
//
// Style guide: Table.Table-like

// Edit Table
//
// You can add a input to the table by adding the following class to the cell `.ds-table__cell--editable`.
//
// Markup: ../../templates/partials/base/table/table-edit-table.hbs
//
// Weight: -7
//
// Style guide: Table.Editable
