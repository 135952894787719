.carousel {
  height: 400px;
  width: 100%;
}

.pStyles {
  padding: 0 20px;
}

.welcome {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
}

.secondP {
  margin-top: -9px;
}

.thirdP {
  margin-top: 28px;
}

.searchContainer {
  margin-top: 70px;
}

.searchContainer > div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.searchContainer > div > input[type="search"] {
  height: 70px;
  max-width: 600px;
  width: 90%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-wrap: wrap;
}

.buttons .button {
  width: 200px;
  margin: 0 15px 15px 15px;
}

.iconButton {
  height: 25px;
  margin-right: 15.5px;
}

@media screen and (max-width: 868px) {
  .carousel {
    display: none;
  }
}
