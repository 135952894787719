.paper {
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(38, 50, 56, 0.1);
  /* padding: 16px 32px; */
  padding: 0px 0px;
  box-sizing: border-box;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 5px;
}
