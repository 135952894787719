.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 15px;
  padding: 5px 50px 20px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #9aa5b8;
  width: 100%;
  transition-duration: 0.2s;
  cursor: pointer;
}
.eccncomponentleft {
  margin-left: 20px;
}
.container.selected {
  border-color: #0085ad;
  border-width: 2px;
  background-color: rgba(0, 133, 173, 0.07);
}

.title {
  margin: 0;
  color: #007bff;
}

.jaune {
  background-color: yellow;
  padding: 0;
}
.content {
  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 0px;
}

.button {
  text-align: left;
  color: #007aff;
  text-decoration: underline;
  outline: none;
}
.button1 {
  text-align: left;
  color: #007aff;
  text-decoration: none;
  outline: none;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonsRow1 {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
}
.tdeccn {
  vertical-align: top !important;
}
