.ds-notification {
  position: relative;
  max-width: 280px;
  min-height: 93px;
  padding: $notifications-padding-medium $notifications-padding-large
    $notifications-padding-medium $notifications-padding-medium;
  margin-bottom: $notifications-padding-medium;
  border-left: 4px solid transparent;
  border-radius: $notifications-border-radius;
  color: $notifications-color;
  font-size: 13px;
  font-size: 400;
  background: $notifications-bg;
  &::before {
    position: absolute;
    top: 9px;
    left: 12px;
    font-size: 18px;
  }
  .ds-button--close {
    position: absolute;
    top: 7px;
    right: 1px;
    font-size: 16px;
    span {
      color: $gray-200;
    }
  }
}

.ds-notification__title {
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
}

.ds-notification__text {
  display: block;
  margin-top: $notifications-text-margin-top;
}

.ds-notification {
  @each $name, $border-color in $notifications-variants-list {
    &--#{$name} {
      border-color: $border-color;
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Notifications
//
// Provide a short, timely and relevant information to the user.
//
//
// Style guide: Notification

// Examples
//
// The style of your notification depend of the message you want to provide, use the right class for this :
// * `.ds-notification--success` : success message.
// * `.ds-notification--warning` : warning message.
// * `.ds-notification--error` : error message.
// * `.ds-notification--info` : neutral message.
//
// Pay attention to the use of `role` attribute :
// * `role="alert"` : used to communicate an important and usually time-sensitive message to the user.
// * `role="status"` : used for an advisory information for the user that is not important enough to justify an alert.
//
// Markup: ../../templates/partials/components/notifications/notifications-overview.hbs
//
// Weight: -10
//
// Style guide: Notification.Example
