.ds-rating {
  white-space: nowrap;

  &__star {
    @extend .ds-text-variant--hide;
    display: inline-block;
    height: 24px;
    width: 20px;
    background: url("data:image/svg+xml,%3Csvg width='20' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-2-2h24v24H-2z'/%3E%3Cpath d='M20 7.24l-7.19-.62L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27 16.18 19l-1.63-7.03L20 7.24zM10 13.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L10 4.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L10 13.4z' fill='%23647592'/%3E%3C/g%3E%3C/svg%3E")
      no-repeat center center transparent;
    background-size: 100% auto;

    &--half-filled {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-2-2h24v24H-2z'/%3E%3Cpath d='M20 7.24l-7.19-.62L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27 16.18 19l-1.63-7.03L20 7.24zM10 13.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L10 4.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L10 13.4z' fill='%23647592' fill-rule='nonzero'/%3E%3Cpath fill='%2300AEC7' d='M10 15.27V0L7.19 6.63 0 7.24l5.46 4.73L3.82 19z'/%3E%3C/g%3E%3C/svg%3E");
    }

    &--filled {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-2-2h24v24H-2z'/%3E%3Cpath fill='%2300AEC7' fill-rule='nonzero' d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Rating
//
// Used to indicate the current page’s location within a navigational hierarchy.
//
// Markup: ../../templates/partials/components/rating/rating.hbs
//
// Style guide: Rating
