.body {
  margin: 50px;
}

.headerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.body input,
.body select {
  height: 40px;
}
