.input {
  position: relative;
  width: 100%;
  height: 40px;
  font-family: inherit;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid rgb(122, 134, 154);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.input input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.input_disabled {
  font-family: inherit;
  font-size: 12px;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid rgb(122, 134, 154);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border-color: #e0e3e9;
  background-color: #eff1f4;
}

.input_disabled input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
