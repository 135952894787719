.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 75px;
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0 50px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
.footer button:nth-child(2) {
  margin-left: auto;
}

.footer > button {
  margin: 0 5px;
}

.width_100 {
  width: 100%;
}
