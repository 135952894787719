@import "../../lib/styles/base";

.ds-bottom-nav {
  display: flex;
  justify-content: center;
  height: 56px;
  background-color: $secondary-blue-2-600;
}

.ds-bottom-nav__action {
  padding: 6px 12px 8px;
  min-width: 80px;
  max-width: 168px;
  color: $white;
  opacity: 0.5;
  flex: 1;
  cursor: pointer;
  &:hover {
    color: $white;
  }
  &.ds-bottom-nav__action--active {
    opacity: 1;
  }

  .ds-bottom-nav__action__wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .ds-bottom-nav__action__label {
    font-size: 14px;
  }
}
