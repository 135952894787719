.ds-paper {
  padding: 16px;
}
.ds-paper--elevation1 {
  box-shadow: 0 1px 2px 0 rgba(80, 93, 115, 0.25);
}
.ds-paper--elevation2 {
  box-shadow: 0 2px 4px 0 rgba(80, 93, 115, 0.25);
}
.ds-paper--elevation4 {
  box-shadow: 0 4px 8px 0 rgba(80, 93, 115, 0.25);
}
.ds-paper--elevation8 {
  box-shadow: 0 8px 16px 0 rgba(80, 93, 115, 0.25);
}
