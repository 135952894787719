.container {
  background-color: #0d335f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cardLogin {
  max-width: 500px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .passwordContainer {
  display: flex;
  align-items: center;
  position: relative;
} */

.togglePassword {
  position: absolute;
  right: 8px;
  top: 50%;
}

.feedbackContainer {
  margin: 10px 0;
}

.feedback {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.feedback.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.feedback.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.moreDetails,
.ssoButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moreDetails:hover,
.ssoButton:hover {
  background-color: #e9ecef;
}

.error {
  border: 1px solid red;
  margin-bottom: 25px;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}

.success {
  border: 1px solid green;
  margin-bottom: 25px;
  padding: 5px;
  background-color: rgba(0, 255, 0, 0.1);
  color: green;
}
.body .row .input .searchButton {
  background-color: #99a5b8;
  height: 33px;
  width: 34px;
  margin-top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
