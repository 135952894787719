.body {
  padding: 40px 40px 150px 40px;
}
.historicRow {
  background-color: #f5f5f5;
}
.cellPositive {
  color: black !important;
}

.cellNegative {
  color: red !important;
}

.body .form {
  margin: 25px 0;
}

.body .form .label {
  color: #647592;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.textInputNoMargin {
  margin-bottom: 0 !important;
}

.customPadding {
  padding: 4 !important;
}

.body .form .label span {
  color: red;
}

.body .form .input > div {
  margin-bottom: 0;
}

.body .form select,
.body .form input {
  width: 100%;
}

.body .divider {
  margin-bottom: 25px;
  height: 1px;
  width: 100%;
  background-color: #d0d0d0;
}

.body .form label {
  color: #647592;
  font-size: 13px;
  font-weight: 400;
}

.body .form .addButton {
  margin-bottom: 25px;
}

.body .form .button {
  margin-right: 20px;
}

.body .form .preview {
  margin-top: 5px;
}

.body table th,
.body table td {
  padding: 12px 0px;
}

.body .flowSection .button {
  width: 100%;
  max-width: 200px;
}

.body .marginTop {
  margin-top: 50px;
}

.left_padding_1 {
  padding-left: 10px;
}

.left_padding_2 {
  padding-left: 20px;
}

.margin_top_bottom_1 {
  margin: 1rem 0 1rem 0;
}

.opacity_1 {
  opacity: 1 !important;
}

.button_clickable {
  cursor: pointer;
}

input:disabled,
textarea:disabled,
select:disabled {
  color: #000000 !important;
}

.disabledRadio > * {
  color: #000000 !important;
}

.widthTextfield {
  width: 200px !important;
}
.cellWithSeparator {
  border-right: 1px solid rgba(0, 0, 0, 0.12); /* Vous pouvez ajuster la couleur et la taille selon vos besoins */
}

.cellWithSeparator:last-child {
  border-right: none;
}
.custom-dropdown-position .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.licenseCard,
.commandCard {
  border: 1px solid #1b5ada;
  border-radius: 5px;
  padding: 20px;
  width: 30%;
}

h3 {
  margin-bottom: 10px;
}

Table {
  width: 100%;
}

TableCell {
  border-bottom: none;
}

.body {
  padding: 20px;
}

.button1 {
  text-align: left;
  background-color: #1b5ada;
  text-decoration: underline;
}
