.ds-picklist {
  position: relative;
}

.ds-picklist__button {
  padding: 2px 7px;
  font-size: 13px;
  font-weight: 500;
  color: $filter-color;
  border: 1px solid $filter-border-color;
  background: transparent;
  transition: 0.25s;

  &:focus {
    color: $filter-color-hover;
    border-color: $filter-border-color-hover;
  }

  &:hover,
  &:active {
    outline: 0;
    color: $filter-color-hover;
    border-color: $filter-border-color-hover;
  }
  &.ds-picklist__button--active {
    color: $filter-color-active;
    border: 1px solid $filter-border-color-active;
    background: $filter-bg-color-active;

    &:focus {
      color: $filter-color-active;
      border-color: $filter-border-color-active;
      background: $filter-bg-color-active;
    }

    &:hover,
    &:active {
      outline: 0;
      color: $filter-color-active;
      border-color: $filter-border-color-hover-active;
      background: $filter-border-color-hover-active;
    }
  }
}

.ds-picklist__button--active {
  span {
    &:first-child {
      &::before {
        display: none;
      }
    }
    &::before {
      content: "-";
      margin-right: 5px;
      color: $white;
    }
  }
}

.ds-picklist__choices {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: 207px;
  font-size: 13px;
  font-weight: 400;
  background: #fff;
  box-shadow: 0 2px 10px rgba($grayscale-700, 0.35);
}

.ds-picklist__form {
  padding: 16px;
  .ds-button--small {
    min-width: 0;
    padding: 5px 8px 6px;
  }
  .ds-form-group-submit {
    margin-top: 20px;
  }
  button {
    margin-left: 12px;
  }
}

.ds-picklist__inner {
  position: relative;
  padding-top: 16px;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 36px;
  }
  &::before {
    @include triangle(10px, $grayscale-700, top);
    opacity: 0.05;
    top: -11px;
  }
  &::after {
    @include triangle(8px, $white, top);
    top: -8px;
  }
}

.ds-picklist__reset {
  position: absolute;
  top: 6px;
  right: 3px;
  font-size: 13px;
  color: $grayscale-600;
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Picklist
//
// Picklists provide a user a selection of multiple items from a dropdown list.
//
// Style guide: Picklist

// Normal
//
// Picklist is a button `.ds-picklist__button` wrapped in `.ds-picklist` container.
//
// Markup: ../../templates/partials/patterns/picklist/picklist.hbs
//
// Weight: -10
//
// Style guide: Picklist.Empty

// Dropdown menu
//
// When a user click on the picklist button, a menu dropdown (`.ds-picklist__choices`) appear below.
//
// Markup: ../../templates/partials/patterns/picklist/picklist-dropdown-empty.hbs
//
// Weight: -9
//
// Style guide: Picklist.Dropdown-Empty

// Dropdown with checkbox
//
// Weight: -8
//
// Style guide: Picklist.Checkbox

// Dropdown list
//
// The menu dropdown (`.ds-picklist__choices`) can contain a list of checkbox.
//
// Markup: ../../templates/partials/patterns/picklist/picklist-dropdown.hbs
//
// Weight: -10
//
// Style guide: Picklist.Checkbox.Dropdown

// Filled dropdown list
//
// If user select some item, item's name appear instead of `label` picklist button and the picklist button become active.
//
// Markup: ../../templates/partials/patterns/picklist/picklist-filled-dropdown.hbs
//
// Weight: -9
//
// Style guide: Picklist.Checkbox.Filled-Dropdown

// Filled
//
// When user have finished to select some item and closed the dropdown list, item's name appear instead of label picklist button and the picklist button stay active. For this reason you should add `aria-label` attribute on `.ds-picklist__button`.
//
// Markup: ../../templates/partials/patterns/picklist/picklist-filled.hbs
//
// Weight: -8
//
// Style guide: Picklist.Checkbox.Filled
