/**
 * Form
 * reset browser form style
 *
 */

//  Reset default style
// -------------------------------------------------------------------

.ds-fake-label,
.ds-label,
label {
  display: block;
  color: $label-color;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: $spacing-xs;

  .ds-mandatory {
    color: $label-mandatory;
  }

  &.ds-label--strong {
    margin-bottom: $spacing-rg;
    font-size: 14px;
    font-weight: 700;
    color: $label-strong-color;
  }
}

form,
fieldset {
  border: none;
}

fieldset {
  margin: $spacing-lg 0;
}

[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea,
.ds-fake-input {
  display: block;
  border: 1px solid $input-border-color;
  padding: $spacing-sm $spacing-rg;
  min-height: 32px;
  line-height: 1;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  box-shadow: none;
  border-radius: $input-border-radius;
  text-align: left;
  color: $input-color;
  background-color: $input-bg;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:hover {
    border-color: $input-border-color-hover;
  }

  &:focus {
    outline: 0;
    border-color: $input-border-color-focus;
  }

  &.disabled,
  &:disabled {
    color: $input-color-disabled;
    border-color: $input-border-color-disabled;
    background-color: $input-bg-disabled;
    user-select: none;
    pointer-events: none;
  }

  .ds-form--dark & {
    color: $white;
    border-color: $white;
    background-color: transparent;
  }

  .ds-form--success & {
    border-color: $color-success;
  }

  .ds-form--error & {
    border-color: $color-danger;
  }

  .ds-form-group & {
    width: 100%;
  }
}

[type="date"],
[type="time"] {
  &.disabled,
  &:disabled {
    opacity: 0.35;
    user-select: none;
    pointer-events: none;
  }
}

[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

textarea {
  height: auto;
}

[type="search"] {
  padding-left: $spacing-xl;
  background-repeat: no-repeat;
  background-position: center left 9px;
  background-image: svg-uri(
    '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>Icons / Magnifying Glass</title><g fill="none" fill-rule="evenodd"><path d="M-2-2h20v20H-2z"/><path d="M10.917 9.667h-.659l-.233-.225a5.393 5.393 0 0 0 1.308-3.525 5.417 5.417 0 1 0-5.416 5.416 5.393 5.393 0 0 0 3.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0a3.745 3.745 0 0 1-3.75-3.75 3.745 3.745 0 0 1 3.75-3.75 3.745 3.745 0 0 1 3.75 3.75 3.745 3.745 0 0 1-3.75 3.75z" fill="#647592" fill-rule="nonzero"/></g></svg>'
  );

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &.ds-field--dark {
    color: $input-color-dark;
    border-color: $input-border-color-dark;
    background-color: $input-bg-dark;
    background-image: svg-uri(
      '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>Icons / Magnifying Glass</title><path class="st0" fill="none" d="M-2-2h20v20H-2z"/><path class="st1" fill="#b3b4b7" d="M10.9 9.7h-.7l-.2-.3c.8-.9 1.3-2.2 1.3-3.5 0-3-2.4-5.4-5.4-5.4S.5 2.9.5 5.9c0 3 2.4 5.4 5.4 5.4 1.3 0 2.6-.5 3.5-1.3l.2.2v.7l4.2 4.2 1.2-1.2-4.1-4.2zm-5 0C3.8 9.7 2.1 8 2.1 5.9s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8S8 9.7 5.9 9.7z"/></svg>'
    );

    &::-webkit-input-placeholder {
      color: $input-placeholder-color-dark;
    }
  }
}

.ds-input-icon {
  position: relative;

  &.ds-input-icon--left {
    [type="color"],
    [type="date"],
    [type="datetime-local"],
    [type="email"],
    [type="month"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="submit"],
    [type="tel"],
    [type="text"],
    [type="time"],
    [type="url"],
    [type="week"],
    .ds-fake-input {
      padding-left: $spacing-xxl;
    }
  }

  &.ds-input-icon--right {
    [type="color"],
    [type="date"],
    [type="datetime-local"],
    [type="email"],
    [type="month"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="submit"],
    [type="tel"],
    [type="text"],
    [type="time"],
    [type="url"],
    [type="week"],
    .ds-fake-input {
      padding-right: $spacing-xl;
    }
  }

  .ds-input-icon__item-left {
    position: absolute;
    top: 9px;
    color: $input-icon-color;

    span {
      font-size: 20px;
      line-height: 1;
    }
  }

  .ds-input-icon__item-right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 37px;
    padding: 0;
    color: $white;

    span {
      font-size: 20px;
      line-height: 1;
      vertical-align: middle;
    }
  }
}

.ds-input-icon__item-left {
  left: 10px;
}

.ds-input-icon__item-right {
  right: 0;
}

::-webkit-input-placeholder {
  color: $input-placeholder-color;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $input-placeholder-color;
}

progress {
  width: 100%;
  vertical-align: middle;
}

//  Select
// -------------------------------------------------------------------

select {
  padding: $spacing-sm $spacing-xl $spacing-sm $spacing-sm;
  background-repeat: no-repeat;
  background-position: center right 9px;
  background-image: svg-uri(
    '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#647592" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
  );
  /* hiding IE11 arrow */
  &::-ms-expand {
    display: none;
  }

  &:hover,
  &:focus {
    background-image: svg-uri(
      '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#505d73" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
    );
  }

  &.disabled,
  &:disabled {
    background-image: svg-uri(
      '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#c1c7d3" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
    );
  }
}

//  Radios + Checkbox
// -------------------------------------------------------------------

.ds-checkbox,
.ds-radio {
  position: relative;
  padding-left: 0;
  margin-right: $spacing-md;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: $spacing-rg;
    text-transform: none;
    line-height: 1;
    font-size: 14px;
    font-family: inherit;
    color: $label-radio-color;
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;
  }

  input[type="checkbox"] {
    &:focus {
      outline: 1px dotted $grayscale-500;

      & + label::before {
        outline: thin dotted;
        outline-offset: -2px;
      }
    }
  }
}

.ds-radio:not(.ds-radio--icon) {
  label {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      margin-left: -$spacing-rg;
      border: 1px solid $input-border-color;
      border-radius: 50%;
      background-color: $input-bg;
      cursor: pointer;
      transition: border 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 6px;
      height: 6px;
      left: 5px;
      top: 5px;
      cursor: pointer;
      margin-left: -$spacing-rg;
      border-radius: 50%;
      transform: scale(0, 0);
      transition: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }

  input[type="radio"] {
    &:checked {
      & + label {
        &::before {
          background-color: $label-radio-bg-checked;
        }

        &::after {
          transform: scale(1, 1);
          background-color: $white;
        }
      }

      &:disabled + label {
        &::before {
          background-color: $white;
          border-color: $label-checkbox-bg-disabled;
        }

        &::after {
          transform: scale(1, 1);
          background-color: $label-radio-bg-disabled;
        }
      }
    }

    &:disabled + label {
      color: $input-color-disabled;
      pointer-events: none;

      &::before {
        background-color: $white;
        border-color: $label-checkbox-bg-disabled;
      }

      &::after {
        transform: scale(0, 0);
      }
    }
  }
}

.ds-radio--large {
  label {
    padding-left: 20px;

    &::before {
      width: 24px;
      height: 24px;
      top: -5px;
    }

    &::after {
      width: 8px;
      height: 8px;
      left: 8px;
      top: 3px;
    }
  }
}

//  Checkbox
// -------------------------------------------------------------------

.ds-checkbox {
  label {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      margin-left: -$spacing-rg;
      border: 1px solid $input-border-color;
      cursor: pointer;
      background-color: $input-bg;
      transition:
        border 0.15s ease-in-out,
        color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      left: -10px;
      font-size: 11px;
      line-height: 16px;
      cursor: pointer;
    }
  }

  input[type="checkbox"] {
    &:checked,
    &:indeterminate {
      & + label {
        &::before {
          background-color: $label-checkbox-bg-checked;
        }

        // &::after {
        //   // exclude font icon for horizon
        //   // @include ds-icon-styles;
        //   content: ds-icon-char(check_checkbox);
        //   text-align: center;
        //   color: $white;
        // }
      }

      &:disabled {
        & + label {
          &::before {
            border-color: $label-checkbox-bg-disabled;
            background-color: $label-checkbox-bg-disabled;
          }
        }
      }
    }

    // &:indeterminate {
    //   & + label {
    //     &::after {
    //       content: ds-icon-char(indeterminate) !important;
    //       font-size: 10px;
    //     }
    //   }
    // }

    &:disabled + label {
      color: $input-color-disabled;
      pointer-events: none;

      &::before {
        border-color: $label-checkbox-bg-disabled;
      }
    }
  }
}

.ds-checkbox--large {
  label {
    padding-left: 20px;

    &::before {
      width: 24px;
      height: 24px;
      font-size: 20px;
      top: -5px;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      top: -1px;
      left: -9px;
      font-size: 16px;
      line-height: 1rem;
    }
  }

  // input[type="checkbox"] {
  //   &:indeterminate {
  //     & + label {
  //       &::after {
  //         content: ds-icon-char(indeterminate) !important;
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
}

// Error / Success / Info

.ds-form--success {
  .ds-form-helptext {
    color: $color-success;
  }
}

.ds-form--error {
  .ds-label--info,
  .ds-form-counter,
  .ds-form-helptext {
    color: $color-danger;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Forms
//
// Examples and usage guidelines for form control styles, layout options, and custom components to create a wide variety of forms.
//
// Style guide: Forms

// Overview
//
// The example below show the general pattern for form patterns. <b>Use the `.ds-form-group` class on the div wrapping your `input` and `label`.</b> <br>
// Don't forget to associate `for` attribute with input's `id`.
//
// Keep reading for documentation on required classes, form layout, and more.
//
// Markup: ../../templates/partials/base/forms/form-overview.hbs
//
// Weight: -10
//
// Style guide: Forms.Overview

// Text inputs
//
// Use these input types to create a text field: `text`, `date`, `datetime`, `datetime-local`, `email`, `month`, `number`, `password`, `tel`, `time`, `url`, and `week`.
// When a filed is required add `*` to label and `aria-required` to input.
//
// Markup: ../../templates/partials/base/forms/form-text-input.hbs
//
// Weight: -10
//
// Style guide: Forms.Text

// Textarea
//
// The `textarea` element creates a multi-line text input. <b>`textarea` and `label` have to be wrapped in a `.ds-form-group` container.</b>
//
// Markup: ../../templates/partials/base/forms/form-textarea.hbs
//
// Weight: -9
//
// Style guide: Forms.Textarea

// Select
//
// Weight: -8
//
// Style guide: Forms.Select

// Normal
//
// Use select menus to combine many choices into one menu. <b>`select` and `label` have to be wrapped in a `.ds-form-group` container.</b>
//
// Markup: ../../templates/partials/base/forms/form-select.hbs
//
// Weight: -10
//
// Style guide: Forms.Select.Normal

// With inline label
//
// Use grid system if you want to inline `label` with `input`.
//
// Markup: ../../templates/partials/base/forms/form-label-inline.hbs
//
// Weight: -9
//
// Style guide: Forms.Select.LabelInline

// Checkboxes & radios
//
// Use groups of checkboxes when the user may select multiple choices from a list, and use radio buttons when the user must select only one choice. The `for` attribute is mandatory to bind our custom radio/checkbox button with the `input`. The input's `id` and the label's `for` attribute must have the same value.
//
// Weight: -7
//
// Style guide: Forms.Checkbox-radios

// Checkbox
//
// Add the class `.ds-checkbox` to the `.ds-form-group` container to style checkbox elements.
// Add the class `.ds-checkbox--large` for larger checkbox elements.
//
// Markup: ../../templates/partials/base/forms/form-checkbox.hbs
//
// Weight: -10
//
// Style guide: Forms.Checkbox-radios.Checkbox

// Radio
//
// Add the class `.ds-radio` to `.ds-form-group` container to style radio elements. Add radio buttons to a group by adding the `name` attribute along with the same corresponding value for each of the radio buttons in the group. Create disabled radio buttons by adding the `disabled` attribute as shown below.
// <br> Add the `.ds-radio--large` class to the container for larger radio elements.
//
// Markup: ../../templates/partials/base/forms/form-radios.hbs
//
// Weight: -9
//
// Style guide: Forms.Checkbox-radios.Radio

// Search inputs
//
// In a search field a search icon is combined with a label. This input field allows a user to enter search terms.
//
// Markup: ../../templates/partials/base/forms/form-search-input.hbs
//
// Weight: -6
//
// Style guide: Forms.Search

// Inputs with icon
//
// In a search field a search icon is combined with a label. This input field allows a user to enter search terms.
//
// Markup: ../../templates/partials/base/forms/form-input-with-icon.hbs
//
// Weight: -5
//
// Style guide: Forms.Icon

// Inputs with button
//
// In a search field a search icon is combined with a label. This input field allows a user to enter search terms.
//
// Markup: ../../templates/partials/base/forms/form-input-with-button.hbs
//
// Weight: -4
//
// Style guide: Forms.Buttons

// Label
//
// `label` form have to be associated with `input` field. To do so, add the input's `id` as the value of the `for` attribute of the label.
//
// Weight: -4
//
// Style guide: Forms.Label

// No label
//
// To remove a label from an input form element, don't forget to add an `aria-label` attribute.
//
// Markup: ../../templates/partials/base/forms/form-label-no-label.hbs
//
// Weight: -10
//
// Style guide: Forms.Label.No-label

// Fake label
//
// In some cases, to keep the structure of the page intact, you would need to have a fake label. To do so use `.ds-fake-label` div.
//
// Markup: ../../templates/partials/base/forms/form-label-fake-label.hbs
//
// Weight: -9
//
// Style guide: Forms.Label.Fake-label

// Help text
//
// Block-level help text in forms can be created after `input` by using `.ds-form-helptext` div.
//
// Markup: ../../templates/partials/base/forms/form-help-text.hbs
//
// Weight: -3
//
// Style guide: Forms.Help-text

// Icon
//
// If you want to have an interactive help button, create it next to the label.
//
// Markup: ../../templates/partials/base/forms/form-help-text-icon.hbs
//
// Weight: -10
//
// Style guide: Forms.Help-text.Icon

// Character counter
//
// Use character counter where a character restriction is in place by adding `.ds-form-counter` span after a `textarea` field.
//
// Markup: ../../templates/partials/base/forms/form-textarea-counter.hbs
//
// Weight: -9
//
// Style guide: Forms.Help-text.Counter

// Error & success
//
// To indicate invalid and valid form fields add `.ds-form--error` or `.ds-form--success` classes to `.ds-form-group` container. <br>
// <i><b>Good practice :</b> add a contextual help-text to give feedback to the user.</i>
//
// Markup: ../../templates/partials/base/forms/form-error-success.hbs
//
// Weight: -8
//
// Style guide: Forms.Help-text.Error-success

// Form layout
//
// By default form stacks fields vertically. Additional classes can be used to vary this layout.
//
// Weight: -2
//
// Style guide: Forms.Form-layout

// Form row
//
// Use `.ds-form-layout` to arrange multiple fields in a row. This class use flexbox css properties, so items will be arranged horizontally with a same width.
//
// Markup: ../../templates/partials/base/forms/form-layout-form-row.hbs
//
// Weight: -10
//
// Style guide: Forms.Form-layout.Form-row

// Radio/checkboxes inline
//
// Wrap checkbox or radio patterns in `.ds-control-inline` div to stack them in a single row.
//
// Markup: ../../templates/partials/base/forms/form-layout-radio-row.hbs
//
// Weight: -9
//
// Style guide: Forms.Form-layout.Radio-row
