body {
  &.ds-no-scroll {
    overflow: hidden;
  }
  &.ds-header-app--menu-open {
    position: relative;
    min-height: 100vh;
  }
}

%visually-hidden {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
}

.ds-text--hide {
  @extend %visually-hidden;
}

.ds-text-variant--hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
}

.ds-img-responsive {
  max-width: 100%;
  height: auto;
}

.ds--show {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
}

.ds-element--hide {
  opacity: 0 !important;
  visibility: inherit !important;
  pointer-events: none !important;
}
