.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay > div {
  height: 100px;
  width: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
