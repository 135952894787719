.ds-tabs {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: 6px;
    border-bottom: 1px solid $tab-border-color;
  }
  &.ds-tabs--secondary {
    border-color: $tab-border-color-secondary;
    .ds-tabs__item {
      &.ds-tabs__item--active {
        &::after {
          background-color: $tab-border-color-active-secondary;
        }
        button,
        a {
          color: $tab-color-active-secondary;
        }
      }
    }
  }
}
.ds-tabs__list {
  margin: 0;
  padding-left: 0;
  padding-bottom: $tabs-link-padding-topBotton;
  @include media-breakpoint-down(md) {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.ds-tabs__item {
  display: inline-block;
  position: relative;
  margin: 0 $tabs-item-margin-rightLeft $tabs-item-margin-bottom;
  font-size: 14px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: transparent;
  }
  &.ds-tabs__item--active {
    &::after {
      background-color: $tab-border-color-active;
    }
    button,
    a {
      color: $tab-color-active;
    }
  }
  button,
  a {
    display: block;
    padding: $tabs-link-padding-topBotton 0;
    border: 0;
    cursor: pointer;
    color: $tab-color-inactive;
    font-weight: 500;
    text-decoration: none;
    background: none;
    transition: 0.25s all;
    &:hover,
    &:focus {
      color: $tab-color-active;
    }
  }
}

.ds-tabs--dark {
  border-color: $tab-border-color-dark;
  .ds-tabs__item {
    &::after {
      bottom: -2px;
    }
    &.ds-tabs__item--active {
      &::after {
        background-color: $tab-border-color-active-dark;
      }
      button,
      a {
        opacity: 1;
        color: $tab-color-dark;
      }
    }
    button,
    a {
      opacity: 0.5;
      color: $tab-color-dark;
      // &:hover,
      &:focus {
        opacity: 1;
        color: $tab-color-dark;
      }
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Tabs
//
// Tabs are elements that help you organize and navigate multiple documents in a single container.
//
//
// Style guide: Tabs

// Normal
//
// The tabs are a container with the class `.ds-tabs`, and each item has the class `.ds-tabs__item`. Each item contains a link to a tab, you should add `aria-selected` attribute on active element.
//
// Markup: ../../templates/partials/components/tabs/tabs.hbs
//
// Weight: -10
//
// Style guide: Tabs.Normal

// Responsive
//
// Show on mobile device how to show a active item at the end of the tab
//
// Markup: ../../templates/partials/components/tabs/tabs-responsive.hbs
//
// Weight: -9
//
// Style guide: Tabs.Responsive

// Secondary version
//
// By adding `.ds-tabs--secondary` class to `.ds-tabs` container, display secondary version of tabs.
//
// Markup: ../../templates/partials/components/tabs/tabs-secondary.hbs
//
// Weight: -8
//
// Style guide: Tabs.Secondary

// Dark version
//
// For dark background add `.ds-tabs--dark` class to `.ds-tabs` container.
//
// Markup: ../../templates/partials/components/tabs/tabs-dark.hbs
//
// Weight: -7
//
// Style guide: Tabs.Dark
