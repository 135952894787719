.divider {
  height: 1px;
  width: 100%;
  background-color: #d0d0d0;
}

.searchContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.searchContainer > div:first-child {
  width: 40%;
  margin-bottom: 0;
  height: 72px;
  display: flex;
  align-items: flex-end;
}

.searchContainer > div > input[type="search"] {
  height: 50px;
  width: 100%;
}

.searchContainer > div svg {
  display: none;
}

.select {
  text-align: left;
  height: 49px;
  width: 175px;
  margin-top: -2px;
}
.selecttype {
  text-align: left;
  height: 50px;
  width: 100px;
  margin-top: -2px;
}
.label {
  display: flex;
  flex-direction: row-reverse;
}

.eccns {
  top: 230px;
  bottom: 100px;
  left: 0;
  width: 100%;
  overflow-y: auto;
}

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #fff;
  box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 15%);
  padding: 0 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer .button {
  margin-left: 30px;
}

.footer .button_prev {
  margin-left: 30px;
  margin-right: 20%;
}

.radioButtons {
  flex-direction: row !important;
}

.emptyECCN {
  margin-top: 1rem;
}

.searchButton {
  margin-left: 10px;
}
