$steppers-icon-size: 24px;

$steppers-item-spacing-top: 2px;
$steppers-item-spacing-bottom: 2px;
$steppers-item-spacing-left: 2px;
$steppers-item-spacing-right: 2px;

$steppers-border-width: 2px;

$steppers-space-bottom: 20px;

$steppers-item-padding-left: 20px;
$steppers-item-padding-right: 8px;

$steppers-border-done-color: $secondary-blue-2-600;
$steppers-current-color: #4a4a4a;
$steppers-grayed-color: $grayscale-600;

.ds-steppers {
  display: flex;
  padding: 0;
  margin: 0;
  &.ds-steppers--horizontal {
    flex-direction: row;
  }
  &.ds-steppers--vertical {
    flex-direction: column;
  }
}

.ds-steppers__step {
  flex: 1 1;
}

.ds-steppers__step__header {
  display: flex;
  margin-top: $steppers-item-spacing-top;
  margin-bottom: $steppers-item-spacing-bottom;
  font-size: 16px;
  font-weight: 500;
  div,
  a {
    display: inline-block;
    padding-left: $steppers-item-padding-left;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    cursor: auto !important;
  }
  &.ds-steppers__step__header--alone,
  &:last-child {
    div,
    a {
      &::after {
        display: none;
      }
    }
  }
  .ds-steppers--horizontal & {
    min-height: auto;
    width: 100%;
    min-width: 75px;
    text-align: center;
    display: flex;
    flex-direction: column;
    &::before {
      content: "";
      width: calc(
        100% - #{$steppers-icon-size + $steppers-item-spacing-left +
          $steppers-item-spacing-right}
      );
      height: $steppers-border-width;
      background: $steppers-grayed-color;
      transform: translate(
        calc(
          50% + #{$steppers-icon-size + $steppers-item-spacing-left +
            $steppers-item-spacing-right}
        ),
        calc(#{$steppers-icon-size + $steppers-border-width} / 2)
      );
    }
  }
  .ds-steppers--horizontal .ds-steppers__step--done &,
  .ds-steppers--horizontal .ds-steppers__step--current & {
    &::before {
      background: $steppers-border-done-color;
    }
  }
  .ds-steppers--horizontal .ds-steppers__step:last-child & {
    &::before {
      display: none;
    }
  }
}
.ds-steppers__ico {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0%;
  left: 0;
  width: $steppers-icon-size;
  height: $steppers-icon-size;
  border: 2px solid $steppers-grayed-color;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  color: $steppers-grayed-color;
  background: transparent;
  svg {
    font-size: 16px;
  }
  .ds-steppers--horizontal & {
    position: inherit;
    top: auto;
    margin: auto;
  }
  .ds-steppers__step--done & {
    color: $steppers-border-done-color;
    border-color: $steppers-border-done-color;
    background: $white;
  }
  .ds-steppers__step--current & {
    color: $white;
    border-color: $steppers-border-done-color;
    background: $steppers-border-done-color;
  }
}
.ds-steppers__label {
  display: inline-block;
  margin-top: 0px;
  color: $steppers-grayed-color;
  .ds-steppers__step--current &,
  .ds-steppers__step--done & {
    color: $steppers-current-color;
  }
  .ds-steppers--vertical & {
    padding-left: $steppers-item-padding-left;
    padding-right: $steppers-item-padding-right;
  }
}
.ds-steppers__step--clickable {
  cursor: pointer !important;
}
.ds-steppers__content {
  color: $steppers-grayed-color !important;
  font-size: 14px;
  text-align: center;
  .ds-steppers--vertical & {
    text-align: unset;
    margin-left: calc((#{$steppers-icon-size} - #{$steppers-border-width}) / 2);
    padding-left: calc(
      (#{$steppers-icon-size} - #{$steppers-border-width}) / 2 + #{$steppers-item-padding-left}
    ) !important;
    padding-right: $steppers-item-padding-right;
    border-left: $steppers-border-width solid $steppers-grayed-color;
    padding-bottom: $steppers-space-bottom;
  }
  .ds-steppers--vertical .ds-steppers__step--done &,
  .ds-steppers--vertical .ds-steppers__step--current & {
    border-left: $steppers-border-width solid $steppers-border-done-color;
  }
  .ds-steppers__step--current &,
  .ds-steppers__step--done & {
    color: $steppers-current-color !important;
  }
  .ds-steppers__step:last-child & {
    border-left: 0px !important;
    padding-bottom: 0px !important;
  }
}
// -------------------------------------------------------------------
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
// -------------------------------------------------------------------
// Steppers
// Steppers are a visual representation of a users progress throught a set of logical and numbered steps. Steps are non-editable and could be linear or non-linear.
// Style guide: Steppers
// Overview
//
// Steppers are a container with the class `.ds-steppers`, and each item has the class `.ds-steppers__step`. Each item contains a :
// * `<a>` element when it's a current or done step. You should add `aria-current` attribute to `<a>` element of the current step.
// * `<div>` element when it's a next step.
// Markup: ../../templates/partials/patterns/steppers/steppers.hbs
//
// Weight: -10
//
// Style guide: Steppers.Overview
// Independant step
//
// You can have steps that is out of user progress. Use same `.ds-steppers` pattern of multiple steps for only one `.ds-steppers__step` item and add `.ds-steppers__step--alone` to `.ds-steppers__step`.
//
// Markup: ../../templates/partials/patterns/steppers/steppers-alone.hbs
// Weight: -9
//
// Style guide: Steppers.Alone
// Horizontal
//
// Markup: ../../templates/partials/patterns/steppers/steppers-horizontal.hbs
// Weight: -8
// Style guide: Steppers.Horizontal
