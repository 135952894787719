.carousel {
  height: 400px;
  width: 100%;
}

.pStyles {
  padding: 0 20px;
}
.iframeStyle {
  width: 80%; /* ou une largeur spécifique */
  height: 600px; /* ou une hauteur spécifique */
  margin: auto; /* pour centrer */
  border: 1px solid #ccc; /* donner un bord pour ressembler à une iframe */
  overflow: auto; /* pour ajouter des barres de défilement si nécessaire */
  position: relative; /* ajustez en fonction de vos besoins */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* optionnel, pour un effet d'ombre */
  border-radius: 10px; /* optionnel, pour des coins arrondis */
}
.welcome {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
}

.secondP {
  margin-top: -9px;
}

.thirdP {
  margin-top: 28px;
}

.searchContainer {
  margin-top: 70px;
}

.searchContainer > div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.searchContainer > div > input[type="search"] {
  height: 70px;
  max-width: 600px;
  width: 90%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-wrap: wrap;
}

.buttons .button {
  width: 200px;
  margin: 0 15px 15px 15px;
}
.body {

  flex-direction: column;

  padding-top: 15px;
   padding-left: 25px;
  padding-bottom: 150px;
}
.iconButton {
  height: 25px;
  margin-right: 15.5px;
}

@media screen and (max-width: 868px) {
  .carousel {
    display: none;
  }
}
