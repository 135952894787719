.ds-avatar {
  position: relative;
  display: inline-block;
  max-width: 32px;
}

.ds-avatar--status {
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -2px;
    bottom: 0;
    width: 9px;
    height: 9px;
    border: 2px solid $white;
    border-radius: 50%;
    background: $avatar-bg-color;
  }
  @each $class, $background-color in $avatar-status-color-list {
    &.ds-avatar--#{$class} {
      &::after {
        background-color: $background-color;
      }
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Avatar
//
// Style guide: Avatar

// Normal
//
// `.ds-avatar` is an element with a user's photo. Use `srcset` attribut on `img` for a better rendering on retina screen.
//
// Markup: ../../templates/partials/components/avatar/avatar.hbs
//
// Weight: -10
//
// Style guide: Avatar.Normal

// Status
//
// Add `.ds-avatar--status` to `.ds-avatar` to indicate the availability of a user.
// * `.ds-avatar--status` : default status (offline).
// * `.ds-avatar--available` : online and available.
// * `.ds-avatar--away` : afk.
// * `.ds-avatar--not-disturb` : when someone need to have some peace and quiet.
//
// Style guide: Avatar.Status

// Offline
//
// Markup: ../../templates/partials/components/avatar/avatar-offline.hbs
//
// Weight: -10
//
// Style guide: Avatar.Status.Offline

// Available
//
// Markup: ../../templates/partials/components/avatar/avatar-available.hbs
//
// Weight: -9
//
// Style guide: Avatar.Status.Available

// Away
//
// Markup: ../../templates/partials/components/avatar/avatar-away.hbs
//
// Weight: -8
//
// Style guide: Avatar.Status.Away

// Do not disturb
//
// Markup: ../../templates/partials/components/avatar/avatar-do-not-disturb.hbs
//
// Weight: -7
//
// Style guide: Avatar.Status.NotDisturb
