.container {
  display: flex;

  align-items: center;
  height: 60px;
  background-color: #011133;
}

.logo {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.subLink {
  padding-left: 40px;
}

.subLink,
.fullname {
  color: #647592;
}

.appName {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 180px;
  background-image: linear-gradient(to right, #0085ad, #011133);
}

.row {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0;
}

.menu li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0 16px;
  box-sizing: border-box;
  border-bottom: 4px solid transparent;
}

li.selected {
  border-color: #0085ad;
}

.menu li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 0.9rem;
}

.dropdownMenu {
  height: 24px;
  min-width: 100px;
  padding: 0px 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.dropdownMenulg {
  height: 24px;
  min-width: 60px;
  padding: 0px 5px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.username {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.username:hover {
  cursor: pointer;
}

.hamburgerMenu {
  display: none;
  padding-right: 20px;
}

.drawerMenu {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 375px;
  bottom: 0;
  background-color: #011133;
  z-index: 3;
}

.drawerMenu .drawerHeader {
  padding: 20px 25px;
  justify-content: space-between;
}

.drawerMenu ul {
  margin-top: 50px;
  padding: 0 25px;
  list-style: none;
}

.drawerMenu ul li {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  height: 65px;
}

.drawerMenu ul li a {
  text-decoration: none;
  margin-left: 0;
}

.notification {
  position: fixed;
  top: 70px;
  right: 25px;
  z-index: 5;
}

@media screen and (max-width: 1000px) {
  .logo {
    display: none;
  }
}

@media screen and (max-width: 868px) {
  .menuContainer {
    display: none;
  }

  .hamburgerMenu {
    display: block;
  }
}
