.rightPanel-enter {
  transform: translateX(100%);
}
.rightPanel-enter-active {
  transform: translateX(0%);
  transition: all 300ms ease-out;
}
.rightPanel-exit {
  transform: translateX(0%);
}
.rightPanel-exit-active {
  transform: translateX(100%);
  transition: all 300ms ease-out;
}
