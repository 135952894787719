.ds-menu-expand__item {
  a {
    svg {
      font-size: 14px !important;
      transform: translate(0px, 1px);
    }
  }
}

.ds-menu-expand__item--icon {
  padding: $overflow-menu-padding-small $overflow-menu-padding-large
    $overflow-menu-padding-small 5px !important;
}

.ds-menu-expand__item__label--icon {
  margin-left: $overflow-menu-padding-small;
}

.ds-menu-expand__item--disabled {
  color: $gray-300;
  a,
  button {
    color: $gray-300;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
