.ds-card {
  display: block;
  padding: $card-padding-regular;
  text-decoration: none;
  font-size: 13px;
  color: $black;
  background-color: $card-bg-color;
  transition:
    border-color 0.25s,
    box-shadow 0.25s,
    background 0.25s;
  &.ds-card--border {
    border: 1px solid $card-border-color;
    &.ds-card--clickable {
      &:hover {
        color: $black;
        border-color: $card-border-color-hover;
        box-shadow: 0 2px 6px $card-shadow-color-hover;
      }
      &:focus {
        outline: 0;
        border-color: $card-border-color-focus;
        box-shadow: 0 2px 6px $card-shadow-color-focus;
      }
    }
  }
  &.ds-card--clickable {
    text-decoration: none;
  }

  &.ds-card--expandable {
    padding: 0;
    .ds-card__header {
      margin-bottom: 0;
    }
    .ds-card__title {
      width: 100%;
      span[class^="ds-icon"] {
        font-size: 24px;
        color: $card-icon-header-color;
        transform: rotate(0deg);
        transition: transform 0.2s;
        pointer-events: none;
      }
    }
    .ds-card__link {
      padding: 25px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:focus {
        outline: 0;
      }
    }

    .ds-card__content {
      display: none;
      padding: 0 22px 22px;
    }
    &.ds-show {
      .ds-card__header {
        span[class^="ds-icon"] {
          transform: rotate(180deg);
          transition: transform 0.2s;
        }
      }
      .ds-card__content {
        display: block;
      }
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ds-pagination {
    margin: 0;
  }
  &.ds-card {
    @each $name, $background-color, $color, $border-color in $card-bg-color-list
    {
      &--#{$name} {
        &:focus,
        &:hover,
        &:active {
          color: $color;
          border-color: $border-color;
          background-color: $background-color;
          .ds-card__title {
            color: $color;
          }
        }
      }
    }
  }
  &.ds-card--chart {
    .ds-card--actions {
      margin-top: -10px;
      button {
        margin-left: 5px;
      }
    }
  }
}

.ds-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $card-picture-margin-bottom;
  .ds-card__buttons {
    font-size: 16px;
  }
  .ds-form-group {
    min-width: 50%;
    margin-bottom: 0;
  }
}

.ds-card__title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
  color: $card-title-color;
  .ds-fake-label {
    margin: 5px 0 0;
  }
}

.ds-card__subheader {
  margin: -$card-padding-small 0 $card-padding-medium;
  button {
    margin-bottom: 0;
  }
  .ds-tabs {
    margin: $card-padding-small 0;
    padding: 0;
  }
  .ds-row {
    align-items: center;
  }
  .ds-tabs--item {
    font-size: 13px;
  }
  .ds-form-layout {
    align-items: center;
    margin-left: -$card-padding-regular;
    .ds-form-group {
      margin-left: $card-padding-regular;
      max-width: calc(100% - #{$card-padding-regular});
      &:last-child {
        text-align: right;
      }
    }
  }
}

.ds-card__footer {
  text-align: right;
  margin: $card-footer-margin-top 0 0;
  button,
  a {
    margin-bottom: 0;
  }
  &.ds-card__footer--bg {
    padding: $card-padding-regular $card-padding-medium;
    margin-right: -$card-padding-medium;
    margin-left: -$card-padding-medium;
    margin-bottom: -$card-padding-medium;
    background: $card-footer-background-color;
    .ds-form-group-submit {
      margin-top: 0;
    }
  }
}

//  Enriched card
// -------------------------------------------------------------------

.ds-card--enriched-img-full {
  .ds-card__picture {
    margin: -25px -25px 22px;
  }
}

.ds-card__picture {
  margin-bottom: 22px;
}

// Expandable cards
// -------------------------------------------------------------------

.ds-card__list {
  li {
    list-style-type: none;
    padding: 4px 0;
  }
}
// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Cards
//
// Cards are flexible containers that contain elements and functions about a single subject.
//
// Style guide: Cards

// Normal
//
// Card is a `<div>` element with the `.ds-card` class. Card is dived in two main parts :
// * a header `.ds-card__header` wich contain the title.
// * the card's content (`.ds-card__content`).
//
// Markup: ../../templates/partials/components/cards/card.hbs
//
// Weight: -10
//
// Style guide: Cards.Alone

// Clickable
//
// Card can be an entire link. Use `.ds-card--clickable` to add style on `:hover`, `:focus`.
//
// Markup: ../../templates/partials/components/cards/card-clickable.hbs
//
// Weight: -9
//
// Style guide: Cards.Clickable

// Color
//
// Card can have color on background on `:hover`, `:focus`.
//
// Markup: ../../templates/partials/components/cards/card-color.hbs
//
// Weight: -8
//
// Style guide: Cards.Color

// Listing
//
// To display a list of cards element, use a `<ul>` element with the `.ds-card-listing` class, each `<li>` has the class `.ds-card-listing__item` and contain card element.
//
// Markup: ../../templates/partials/components/cards/card-list.hbs
//
// Weight: -7
//
// Style guide: Cards.Listing

// Card table
//
// A card element can be more complex and contain a table with filter and navigation actions.
//
// Weight: -6
//
// Style guide: Cards.Table

// With paginate
//
// Markup: ../../templates/partials/components/cards/card-table-paginate.hbs
//
// Weight: -10
//
// Style guide: Cards.Table.Paginate

// Chips
//
// Markup: ../../templates/partials/components/cards/card-table-with-chips-paginate.hbs
//
// Weight: -9
//
// Style guide: Cards.Table.Chips

// Progressbar
//
// Markup: ../../templates/partials/components/cards/card-table-with-progressbar-paginate.hbs
//
// Weight: -8
//
// Style guide: Cards.Table.Progressbar

// Toggle
//
// Markup: ../../templates/partials/components/cards/card-table-with-toggle-paginate.hbs
//
// Weight: -7
//
// Style guide: Cards.Table.Toggle

// Chart table
//
// Card can contain chart element with tab navigation panel.
//
// Markup: ../../templates/partials/components/cards/card-chart.hbs
//
// Weight: -5
//
// Style guide: Cards.Chart

// Enriched card
//
// Weight: -4
//
// Style guide: Cards.Enriched

// With picture
//
// Wrapp image in a `<div>` element with `.ds-card__picture` class. Action buttons have to be in a `<div>` element with `.ds-card__footer`.
//
// Markup: ../../templates/partials/components/enriched-card/enriched-card.hbs
//
// Weight: -10
//
// Style guide: Cards.Enriched.WithPicture

// With full picture
//
// Add `.ds-card--enriched-img-full` class to `.ds-card` to have a bleed area picture.
//
// Markup: ../../templates/partials/components/enriched-card/enriched-card-full.hbs
//
// Weight: -9
//
// Style guide: Cards.Enriched.WithFullPicture

// Survey
//
// Survey is s special form that can be display in a card. Use `.ds-strong-label` on `<label>` to add a strong style on it.
//
// Markup: ../../templates/partials/patterns/survey/survey.hbs
//
// Style guide: Survey

// Expandable card
//
// Cards can be expandable if it needs to. Although the structure is different but style still remain.
//
// Weight: -3
//
// Style guide: Cards.ExpandableCard

// Example
//
// A button is in the title to trigger the collapsable part: `.ds-card__content`. Use the `.ds-show` class on the `.ds-card--expandable` element to define the open state. `data-target` and `data-toggle` attribute are used by the javascript animation and `aria-controls` is here for the accessibility of the content.
//
// Markup: ../../templates/partials/components/cards/card-expandable.hbs
//
// Weight: -10
//
// Style guide: Cards.ExpandableCard.Card

// List of Expandable card
//
// When the card is multiple, they are wrapped in a list. Add the `.ds-card--multiple` class  to the `ul` to remove the list-style-type by default.
//
// Markup: ../../templates/partials/components/cards/card-expandable-multiple.hbs
//
// Weight: -8
//
// Style guide: Cards.ExpandableCard.CardMultiple
