.ds-incrementer {
  display: flex;
  max-width: 110px;
  .ds-incrementer__button {
    @extend .ds-button;
    max-width: 32px;
    margin: 0;
    padding: $incrementer-padding-small $incrementer-padding-medium;
    font-size: 10px;
    color: $incrementer-button-color;
    &--disabled {
      color: $incrementer-button-color-disabled;
      cursor: default;
      pointer-events: none;
    }
    &.ds-incrementer__button--plus {
      border-radius: 0 3px 3px 0;
    }
    &.ds-incrementer__button--minus {
      border-radius: 3px 0 0 3px;
    }
  }
  input {
    min-width: 40px;
    border-right: 0;
    border-left: 0;
    text-align: center;
    &:hover,
    &:focus {
      border-color: $input-border-color;
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Incrementer
//
// Incrementer is use to increase or decrease a number value of an input element.
//
// Markup: ../../templates/partials/components/incrementer/incrementer.hbs
//
// Style guide: Incrementer

// Incrementer
//
// Incrementer with a button disabled
//
// Markup: ../../templates/partials/components/incrementer/incrementer-disabled.hbs
//
// Style guide: Incrementer.Disabled
