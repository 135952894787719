.ds-appbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to right, #005587 0%, #00205b 60%, #00205b 100%);
  color: $white;
  z-index: $zindex-sticky;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
}

.ds-appbar--static {
  position: static;
}

.ds-appbar--prominent {
  min-height: 174px;
  transition: 0.3s;
  &.ds-appbar--prominent-collapsed {
    min-height: auto;
    &:not(:last-child) {
      display: none;
    }
  }
}

.ds-appbar-tabs {
  &:after {
    display: none !important;
  }
  ul {
    padding-bottom: 0;
  }
}
