.ds-input-hour {
  position: relative;
  max-width: 85px;
  input::-webkit-clear-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  .ds-field {
    padding: 7px 10px;
  }
}

.ds-input-hour__btn {
  display: block;
  position: absolute;
  top: 52%;
  right: 1px;
  border: 0;
  font-size: 15px;
  line-height: 1;
  color: $hourspicker-btn-color;
  transform: translate(0, -50%);
}

.ds-hours__inner {
  position: relative;
  padding: 16px 14px 28px;
  background: #fff;
  box-shadow: 0 2px 10px rgba($grayscale-700, 0.25);
  @include media-breakpoint-down(md) {
    padding: 16px 8px 28px;
  }
  &::before {
    @include triangle(12px, $grayscale-700, top);
    opacity: 0.05;
    content: "";
    display: block;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &::after {
    @include triangle(10px, $white, top);
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.ds-hours {
  position: absolute;
  width: 204px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: $hourspicker-hours-color;
}

.ds-hours__content {
  display: flex;
  justify-content: center;
  margin: 21px auto 24px;
  & > div {
    position: relative;
    padding: 36px 0;
    width: 37%;
    &:first-child {
      &::after {
        content: ":";
        display: inline-block;
        position: absolute;
        right: -2px;
      }
    }
  }
}

.ds-hours__navigation {
  position: absolute;
  top: 0;
  left: 50%;
  width: 22px;
  height: 22px;
  padding: 0;
  border-radius: 50%;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: $hourspicker-navigation-color;
  border: 1px solid $hourspicker-navigation-border-color;
  transform: translate(-50%, 0);
  &:focus {
    outline: 0;
    color: $btn-color-focus;
    background: $btn-bg-color-focus;
  }
  &:hover,
  &:active {
    color: $btn-text-color-hover;
    border-color: $btn-bg-color-hover;
    background-color: $btn-bg-color-hover;
  }
  &.ds-hours__navigation--last {
    top: auto;
    bottom: 0;
  }
}

.ds-hours__footer {
  button {
    min-width: 70px;
    margin: 0 5px;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Hours picker
//
//
// Style guide: HoursPicker

// Overview
//
// Hours picker is composed by :
// * `.ds-hours__header` header which contain the title.
// * `.ds-hours__content` which contain hour and minute.
// * `.ds-hours__footer` with all actions buttons.
//
// Markup: ../../templates/partials/components/hours-picker/hours-picker.hbs
//
// Weight: -10
//
// Style guide: HoursPicker.Normal
