@import "../../lib/styles/base";
// @import "~horizon-stylesheets/src/scss/base/form";

.ds-checkbox {
  position: relative;
  display: inline-block;
  margin-right: $spacing-md;
  padding-left: 0;
  white-space: nowrap;
  line-height: 1;
  * {
    box-sizing: border-box;
    label {
      position: relative;
      display: inline-block;
      padding-left: 2px;
      font-size: 14px;
      font-family: inherit;
      margin: 0;
      text-indent: 1.5em;
      min-width: calc(1em + 2px);
      &::before,
      &::after {
        content: "";
        width: calc(1em + 2px);
        height: calc(1em + 2px);
        display: block;
        border: 1px solid transparent;
        z-index: 0;
        position: absolute;
        left: 0;
        top: calc((0% - (100% - 1em)) - 8%);
        background-color: transparent;
      }
      &:before {
        border-color: $input-border-color;
      }
    }

    .ds-checkbox-icon {
      position: absolute;
      font-size: 1em;
      width: 1em;
      height: 1em;
      left: 0;
      z-index: 1;
      text-align: center;
      line-height: normal;
      top: -1px;
      opacity: 0;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    &:disabled ~ * {
      opacity: 0.5;
    }
  }
}

.ds-checkbox input:checked ~ * .ds-checkbox-icon,
.ds-checkbox input:indeterminate ~ * .ds-checkbox-icon {
  opacity: 1;
}

.ds-checkbox input:checked ~ * label:after,
.ds-checkbox input:checked ~ * label::after,
.ds-checkbox input:indeterminate ~ * label:after,
.ds-checkbox input:indeterminate ~ * label::after {
  background-color: $label-checkbox-bg-checked !important;
}

.ds-checkbox input:checked ~ * .ds-checkbox-icon,
.ds-checkbox input:indeterminate ~ * .ds-checkbox-icon {
  color: #fff;
}

.ds-checkbox--large {
  * {
    label {
      font-size: 16px;
    }
  }
  .ds-checkbox-icon {
    font-size: 1.2em;
    top: -2px;
  }
}
