.body1 {
  margin: 10px 50px  50px  50px ;
}
.body {
  margin: 50px;
}
table td,
.styles_ds-table-like__2T6GM td,
table .styles_ds-table-like__cell__31plv,
.styles_ds-table-like__2T6GM .styles_ds-table-like__cell__31plv {
  padding: 12px 3px;
}
.headerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.create_account_name {
  display: flex;
  justify-content: space-between;
}
