.ds-autosave--progress {
  svg {
    color: $autosave-icon-progress-color;
    animation-name: rotate;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.ds-autosave--saved {
  svg {
    color: $autosave-icon-saved-color;
  }
}
