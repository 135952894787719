.body {
  padding: 40px 40px 150px 40px;
}

.body .form {
  margin: 25px 0;
}

.body .form .label {
  color: #647592;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.body .form .label span {
  color: red;
}

.body .form .input > div {
  margin-bottom: 0;
}

.body .form select,
.body .form input {
  width: 100%;
}

.body .divider {
  margin-bottom: 25px;
  height: 1px;
  width: 100%;
  background-color: #d0d0d0;
}

.body .form label {
  color: #647592;
  font-size: 13px;
  font-weight: 400;
}

.body .form .addButton {
  margin-bottom: 25px;
}

.body .form .button {
  margin-right: 20px;
}

.body .form .preview {
  margin-top: 5px;
}

.body table th,
.body table td {
  padding: 12px 0px;
}

.body .flowSection .button {
  width: 100%;
  max-width: 200px;
}

.body .marginTop {
  margin-top: 50px;
}

.left_padding_1 {
  padding-left: 10px;
}

.left_padding_2 {
  padding-left: 20px;
}

.margin_top_bottom_1 {
  margin: 1rem 0 1rem 0;
}

.opacity_1 {
  opacity: 1 !important;
}

.button_clickable {
  cursor: pointer;
}

input:disabled,
textarea:disabled,
select:disabled {
  color: #000000 !important;
}

.disabledRadio > * {
  color: #000000 !important;
}
