.ds-modal {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  max-width: 485px;
  margin: auto;
  font-size: 14px;
  color: $modal-color;
  text-align: left;
  background: $white;
  box-shadow: 0 2px 8px rgba($grayscale-700, 0.25);
  &.ds-modal--center {
    text-align: center;
  }
  .ds-button--close {
    position: absolute;
    top: 10px;
    right: 18px;
    padding: 0;
    font-size: 22px;
    color: $grayscale-300;
    cursor: pointer;
  }
}

.ds-modal__header {
  position: relative;
  padding: $modal-padding-medium $modal-padding-large 0;
  .ds-modal--center & {
    padding-top: $modal-padding-xlarge;
  }
  &.ds-modal__header--with-intro {
    border-bottom: 1px solid $grayscale-200;
    margin-bottom: $modal-padding-large;
    padding-bottom: $modal-padding-small;
  }
}

.ds-modal__title {
  // display: block;
  margin-bottom: $modal-padding-medium;
  // font-size: 20px;
  // font-weight: 400;
  // color: $modal-title-color;
}

.ds-modal__content {
  padding: 0 $modal-padding-large $modal-padding-large;
  &.ds-modal__content--fix-height {
    overflow-y: auto;
  }
}

.ds-modal__footer {
  position: relative;
  padding: $modal-padding-medium;
  text-align: right;
  background: $white;
  &.ds-modal__footer--bg {
    background: $modal-footer-background-color;
  }
  > *:not(:last-child) {
    margin: 0 4px;
  }
  .ds-button--link {
    position: absolute;
    left: 24px;
    text-decoration: none;
    font-size: 14px;
    @include media-breakpoint-down(sm) {
      position: relative;
      left: auto;
      margin-bottom: $modal-padding-xsmall;
    }
    span {
      position: relative;
      top: 6px;
      font-size: 22px;
    }
  }
  .ds-button {
    margin: 0 0 0 $modal-padding-regular;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: $modal-padding-xsmall;
      margin-left: 0;
    }
  }
  .ds-modal--center & {
    padding-top: 0;
    text-align: center;
    .ds-button {
      margin: auto;
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Modal
//
// Modals are used to temporary communicate contextual informations or ask actions in a layer above the app.
//
// Style guide: Modal

// Normal
//
// Modals are `.ds-modal` container which contains three block :
// * `.ds-modal__header` : title of the modal and close button
// * `.ds-modal__content` : main content of the modal (text, form, ...)
// * `.ds-modal__footer` : action buttons
//
//
// Markup: ../../templates/partials/patterns/modal/modal.hbs
//
// Weight: -10
//
// Style guide: Modal.Normal

// With form
//
// When modal content contains form, `.ds-modal__content` and `.ds-modal__footer` div have to be wrapped in `form` element.
//
// Markup: ../../templates/partials/patterns/modal/modal-form.hbs
//
// Weight: -9
//
// Style guide: Modal.Form

// Introduction
//
// Introduction text can be display in modal's header. Add `.ds-modal__header--with-intro` class to `.ds-modal__header`.
//
// Markup: ../../templates/partials/patterns/modal/modal-intro.hbs
//
// Weight: -8
//
// Style guide: Modal.Intro

// Text center
//
// Content of the modal can be centered by adding `.ds-modal--center` to `.ds-modal` container.
//
// Markup: ../../templates/partials/patterns/modal/modal-center.hbs
//
// Weight: -7
//
// Style guide: Modal.Center
