.container {
  background-color: #0d335f;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.cardLogin {
  max-width: 500px;
  width: 90%;
}

.error {
  border: 1px solid red;
  margin-bottom: 25px;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}
