// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1-like,
.h2-like,
.h3-like,
.h4-like,
.h5-like,
.h6-like {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1-like {
  font-size: $h1-font-size;
  color: $gray-700;
}

h2,
.h2-like {
  font-size: $h2-font-size;
  color: $gray-600;
}

h3,
.h3-like {
  font-size: $h3-font-size;
  color: $gray-600;
}

h4,
.h4-like {
  font-size: $h4-font-size;
  color: $gray-600;
}

h5,
.h5-like {
  font-size: $h5-font-size;
  color: $gray-500;
}

h6,
.h6-like {
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  color: $gray-500;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

.ds-heading--dark {
  color: $white;
}

.ds-heading--primary {
  color: $secondary-blue-2-600;
}

.ds-heading--error {
  color: $color-danger;
}

.ds-heading--success {
  color: $color-success;
}

.ds-heading--warning {
  color: $color-warning;
}

.ds-heading--info {
  color: $color-info;
}
