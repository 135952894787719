.ds-hours--dialog {
  transform: translate(-33px, 9px);
  z-index: 1200;
}

.ds-hours--hidden {
  display: none;
}

.ds-input-hour--btn {
  transform: scale(0.6) translate(20%, -85%);
}
