.body {
  position: relative;
}

.body .input {
  margin-bottom: 0;
}

.customWidthTextfield {
  width: 200px;
}

.list {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  list-style: none;
  background-color: #ffffff;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-top: 5px;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.list li {
  padding: 10px 16px;
  transition-duration: 0.3s;
}

.list li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.14);
}
