.sucessImage {
  height: 40vh;
  width: 100%;
  background-image: url("../../../../statics/images/screening-success.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.successBody {
  display: flex;
  justify-content: center;
  padding: 50px 25px;
}

.successBody .shieldIcon {
  margin-right: 20px;
}

.messageBody {
  padding: 50px 50px 100px 50px;
}

.messageBody .header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.messageBody .licenses {
  max-width: 400px;
}

.messageBody .licenses .flag {
  margin-left: 10px;
}

.messageBody .results {
  margin-top: 50px;
}

.messageBody .results .subResults {
  padding: 25px 0 0 50px;
}

.arrow {
  display: flex;
  align-items: center;
}

.arrow svg {
  margin-top: -3px;
}

.row {
  display: flex;
  align-items: center;
}

.row .traficLight {
  width: 94px;
  margin-right: 30px;
}

@media screen and (max-width: 868px) {
  .sucessImage {
    display: none;
  }
}
