.ds-dropdown {
  .ds-button {
    padding: 0;
    margin-bottom: 0;
    width: 34px;
    height: 34px;
    font-size: 12px;
    border-left: 0;
  }
  &.ds-dropdown--disabled {
    .ds-button,
    .ds-dropdown__input {
      color: $input-color-disabled;
      border-color: $input-border-color-disabled;
      background-color: $input-bg-disabled;
      user-select: none;
      pointer-events: none;
    }
    .ds-dropdown__input {
      &:after {
        background-image: svg-uri(
          '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#c1c7d3" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
        );
      }
      [class^="ds-icon"] {
        color: $input-color-disabled;
      }
    }
  }
}

.ds-dropdown__input {
  @extend select;
  cursor: pointer;
  user-select: none;
  background-image: none;
  position: relative;
  &.ds-dropdown--with-chips {
    padding: 0;
    &.ds-select-chips--show-content {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 7px;
    width: 12px;
    height: 7px;
    will-change: transform;
    transition: transform 0.2s ease-out;
    background-repeat: no-repeat;
    background-image: svg-uri(
      '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#647592" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
    );
    .ds-show & {
      transform: rotate(180deg);
    }
  }
  &:hover,
  &:focus {
    background-image: none;
    &:after {
      background-image: svg-uri(
        '<svg width="12" height="7" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-5-8h24v24H-5z"/><path fill="#505d73" fill-rule="nonzero" d="M6.995 5.591L12.507.078l1.415 1.415-6.927 6.926L.068 1.493 1.482.078z"/></g></svg>'
      );
    }
  }
  span {
    position: relative;
  }
  .ds-dropdown--icon & {
    position: relative;
    padding-left: $dropdown-padding-large;
    [class^="ds-icon"] {
      position: absolute;
      top: 6px;
      left: 9px;
      font-size: 16px;
      color: $dropdown-icon-color;
    }
  }
}

.ds-dropdown--plus {
  .ds-dropdown__container {
    display: flex;
  }
  .ds-dropdown__menu {
    width: calc(100% - #{$dropdown-padding-large});
  }
}

.ds-dropdown__menu {
  z-index: $zindex-dropdown;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid $dropdown-option-border;
  border-top: 0;
  background: $dropdown-option-bg;
  transition: opacity 0.2s ease-out 0.1s;
  .ds-show & {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }
}

.ds-dropdown__option {
  padding: $dropdown-padding-small $dropdown-padding-medium;
  font-size: 14px;
  list-style: none;
  color: $dropdown-option-color;
  button {
    padding: 0;
    width: 100%;
    user-select: none;
    text-align: left;
  }
  &:hover,
  &:focus {
    background: $dropdown-option-bg-hover;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Dropdown
//
// Use an expandable dropdown menu instead of `<select>` element.
//
// Style guide: Dropdown

// Normal
//
// Dropdown element is divided in two parts :
// * `.ds-dropdown__container` which contains `.ds-dropdown__input` button.
// * `.ds-dropdown__menu` : the dropdown menu with all its items.
//
// Use `.disabled` class on `.ds-dropdown` when you need a desactivate state.
//
// Markup: ../../templates/partials/components/dropdown/dropdown.hbs
//
// Weight: -10
//
// Style guide: Dropdown.Normal

// Icon
//
// Add a `ds-icon` element in `.ds-dropdown__input` when you need an icon to your dropdown.
//
// Markup: ../../templates/partials/components/dropdown/dropdown-icon.hbs
//
// Weight: -9
//
// Style guide: Dropdown.Icon

// More button
//
// Use small version of toggles by adding `.ds-toggles__small` on `ds-form-group`.
//
// Markup: ../../templates/partials/components/dropdown/dropdown-icon-plus.hbs
//
// Weight: -8
//
// Style guide: Dropdown.More

// Dropdown multiple
//
// This component is a dropdown with chips inside.
//
// Markup: ../../templates/partials/components/dropdown/dropdown-multiple.hbs
//
// Weight: -7
//
// Style guide: Dropdown.Multiple
