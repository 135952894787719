a {
  &.ds-button-link--disabled {
    color: $link-color-disabled;
    text-decoration: $link-decoration-disabled;
    pointer-events: none;
  }
}

.ds-link-list {
  padding: 0;
  margin: $link-spacing-medium 0;
}

.ds-link-list__item {
  list-style: none;
  margin: 0 0 $link-spacing-small;
  .ds-link-list--horizontal & {
    display: inline-block;
    margin: 0 0 $link-spacing-small $link-spacing-large;
    &:first-child {
      margin-left: 0;
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Links
//
// Links are used primarly as a navigational element. Links may also change what or how data is displayed.
//
// Markup: ../../templates/partials/components/links/link.hbs
//
// Style guide: Links

// Disabled state
//
// `disabled` attribute is not supportd by `<a>`. Use `.ds-button-link--disabled` with an aria-attribute `aria-disabled="true"` instead.
//
// Markup: ../../templates/partials/components/links/link-disabled.hbs
//
// Weight: -10
//
// Style guide: Links.Disabled

// Vertical list
//
// To display a list of links vertically use a `<ul>` container (`.ds-link-list`) filled with `<li>` (`.ds-link-list__item`) elements containing each link.
//
// Markup: ../../templates/partials/components/links/link-list-vertical.hbs
//
// Weight: -8
//
// Style guide: Links.Vertical

// Horizontal list
//
// Use the `.ds-link-list--horizontal` class on a `<ul>` container to display the links horizontally.
//
// Markup: ../../templates/partials/components/links/link-list-horizontal.hbs
//
// Weight: -7
//
// Style guide: Links.Horizontal
