@import "../../lib/styles/base";

$radius: 8px;

.ds-badge-wrapper {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.ds-badge {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  min-width: $radius * 2;
  height: $radius * 2;
  border-radius: $radius;
  background-color: $color-primary-3;
  color: white;
  transform: scale(1) translate(50%, -50%);
  font-size: 0.65rem;
}

.ds-badge--primary {
  background-color: $color-primary-1;
}

.ds-badge--error {
  background-color: $color-danger;
}

.ds-badge--success {
  background-color: $color-success;
}

.ds-badge--warning {
  background-color: $color-warning;
}

.ds-badge--info {
  background-color: $color-info;
}
