.result {
  position: relative;
  padding: 10px 25px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.result .traficLightBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.result .traficLightBox.red {
  background-color: #ff6b6b;
}

.result .traficLightBox.yellow {
  background-color: #f7b740;
}
.result .traficLightBox.green {
  background-color: #1dd1a1;
}

.result .traficLight {
  width: 42px;
  margin-right: 25px;
}

.result .moreDetails {
  margin-right: 20px;
}

.title {
  display: flex;
  align-items: center;
}

.listname,
.regulation {
  font-size: 16px;
  margin-right: 15px;
}

.result .reasons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.result .reasons label {
  margin-right: 15px;
}

.result .badge {
  padding: 5px 15px;
  color: #ffffff;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
  background-color: #0085ad;
}
