.entity {
  padding: 10px 25px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #ffffff;
  width: 100%;
  cursor: pointer;
}

.entity:hover,
.entity.selected {
  background-color: rgba(0, 168, 255, 0.05);
  border-color: rgba(0, 168, 255, 1);
}
