@import "../../lib/styles/base";
// @import "../../../../horizon-stylesheets/src/scss/base/form";

.ds-radio {
  display: flex;
  align-items: center;
  color: $input-border-color;
  label {
    line-height: 1.2em;
    font-size: 14px;
    font-family: inherit;
    color: $label-radio-color;
    margin: 0;
  }
  &.ds-radio--large {
    label {
      line-height: 1.5em;
    }
  }
  &.ds-radio--standalone {
    display: inline-block;
  }
  &.ds-radio--checked {
    color: $label-radio-bg-checked;
  }
  &.ds-radio--disabled {
    color: $input-color-disabled;
    pointer-events: none;
    label {
      color: $input-color-disabled;
    }
  }
}

.ds-radio-button {
  position: relative;
  display: inline-flex;
  margin: 6px;

  svg {
    font-size: 16px;
    .ds-radio--large & {
      font-size: 24px;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
