.ds-form-group {
  margin-bottom: 24px;
}

.ds-form--dark {
  .ds-label,
  .ds-form-helptext {
    color: $white;
  }
}
