// Options

/*
$zindex-dropdown:       1000 !default;
$zindex-sticky:         1020 !default;
$zindex-fixed:          1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal:          1050 !default;
$zindex-popover:        1060 !default;
$zindex-tooltip:        1070 !default;
*/

// Quickly modify global styling by enabling or disabling optional features.
/*
$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;
*/

//  Colors
//
// (for name of color, see : http://chir.ag/projects/name-that-color)
// -------------------------------------------------------------------

// Grey colors

/*  *****Temporary in comments to test Design Tokens****
$white:    #fff !default;
$black:    #000 !default;
>>>>>>> develop-mobile

$gray-100: #d9d9db !default;
$gray-200: #b3b4b7 !default;
$gray-300: #8f9295 !default;
$gray-400: #6d7073 !default;
$gray-500: #4c5054 !default;
$gray-600: #2e3237 !default;
$gray-700: #14161a !default;

$grayscale-100: #eff1f4;
$grayscale-200: #e0e3e9;
$grayscale-300: #c1c7d3;
$grayscale-400: #9aa5b8;
$grayscale-500: #8390a7;
$grayscale-600: #647592;
$grayscale-700: #505d73;



// Primary colors

$cerulean-blue: #007aa4;
$orient-blue: #004b7c;
$prussian-blue: #001e51;
$horizon-grey: #588ea4;
$heather-grey: #aec2cd;

$color-primary-1: $cerulean-blue !default;
$color-primary-2: $orient-blue !default;
$color-primary-3: $prussian-blue !default;
$color-primary-4: $horizon-grey !default;
$color-primary-5: $heather-grey !default;


$primary-colors: (
  color-1:
    (
      100: #fdd8e5,
      200: #fdd8e5,
      300: #f8b1cc,
      400: #f088b4,
      500: #e65c9b,
      600: #da1884,
      700: #be0a6e
    ),
  color-2:
    (
      100: #e6e6ed,
      200: #cdcddc,
      300: #9e9dbb,
      400: #6f6f9a,
      500: #40467a,
      600: #00205b,
      700: #011133
    ),
  color-3:
    (
      100: #e4e4e9,
      200: #c9c9d3,
      300: #9496a8,
      400: #63657f,
      500: #333857,
      600: #011133,
      700: #010a1f
    ),
  color-4:
    (
      100: #ecf6f9,
      200: #daeef4,
      300: #b5dfe8,
      400: #8ecedd,
      500: #60bfd2,
      600: #00aec7,
      700: #038a9d
    ),
  color-5:
    (
      100: #f7e9f3,
      200: #f0d4e8,
      300: #e0aad2,
      400: #cd80bc,
      500: #ba53a6,
      600: #a51890,
      700: #780a68
    )
) !default;

// Secondary colors
//
// (see _functions.scss to retreive color Sass maps)

$secondary-colors: (
<<<<<<< HEAD
  green-1:
    (
      100: #dff2e7,
      200: #bde5cf,
      300: #9cd9b8,
      400: #79cca1,
      500: #4fbe8a,
      600: #03b075,
      700: #0e8059
    ),
  green-2:
    (
      100: #f2f8e6,
      200: #e6f1cc,
      300: #cde499,
      400: #aed459,
      500: #9cca33,
      600: #84bd00,
      700: #689306
    ),
  green-3:
    (
      100: #fcfbe6,
      200: #f9f8cc,
      300: #f3f299,
      400: #ebea59,
      500: #e7e633,
      600: #e1e000,
      700: #aead06
    ),
  yellow:
    (
      100: #fef8e6,
      200: #fef1cc,
      300: #fde499,
      400: #fbd459,
      500: #fbca33,
      600: #fabd00,
      700: #c19306
    ),
  orange:
    (
      100: #fff1e6,
      200: #ffe3cc,
      300: #ffc899,
      400: #ffa659,
      500: #ff9233,
      600: #ff7700,
      700: #c55f06
    ),
  red:
    (
      100: #fce6ea,
      200: #f9ccd4,
      300: #f499aa,
      400: #ed5974,
      500: #e93355,
      600: #e4002b,
      700: #b00626
    ),
  pink:
    (
      100: #fdd8e5,
      200: #fdd8e5,
      300: #f8b1cc,
      400: #f088b4,
      500: #e65c9b,
      600: #da1884,
      700: #be0a6e
    ),
  purple:
    (
      100: #f7e9f3,
      200: #f0d4e8,
      300: #e0aad2,
      400: #cd80bc,
      500: #ba53a6,
      600: #a51890,
      700: #780a68
    ),
  grey:
    (
      100: #f7f9fa,
      200: #f0f4f6,
      300: #e2e9ed,
      400: #d0dbe2,
      500: #c5d3db,
      600: #b7c9d3,
      700: #8f9ca4
    ),
  grey-2:
    (
      100: #eff5f7,
      200: #dfeaee,
      300: #c0d6de,
      400: #99bcca,
      500: #82adbe,
      600: #6399ae,
      700: #507888
    ),
  light-blue:
    (
      100: #ecf6f9,
      200: #daeef4,
      300: #b5dfe8,
      400: #8ecedd,
      500: #60bfd2,
      600: #00aec7,
      700: #038a9d
    ),
  blue:
    (
      100: #e6f3f6,
      200: #cce6ee,
      300: #99cede,
      400: #59afc9,
      500: #339dbd,
      600: #0085ad,
      700: #066987
    ),
  blue-2:
    (
      100: #e6eef3,
      200: #ccdde7,
      300: #99bbcf,
      400: #5990b0,
      500: #33779f,
      600: #005587,
      700: #06456b
    ),
  hyperblue: (200: #a6e2f9, 400: #00aeef, 600: #0d86d3)
) !default;

// Semantic colors

$color-success: secondary-color(green-1, 600) !default;
$color-warning: secondary-color(yellow, 600) !default;
$color-danger: secondary-color(red, 600) !default;
$color-info: $grayscale-600 !default;
=======

  green-1: (
    100: #dff2e7,
    200: #bde5cf,
    300: #9cd9b8,
    400: #79cca1,
    500: #4fbe8a,
    600: #03b075,
    700: #0e8059
  ),

  green-2: (
    100: #f2f8e6,
    200: #e6f1cc,
    300: #cde499,
    400: #aed459,
    500: #9cca33,
    600: #84bd00,
    700: #689306
  ),

  green-3: (
    100: #fcfbe6,
    200: #f9f8cc,
    300: #f3f299,
    400: #ebea59,
    500: #e7e633,
    600: #e1e000,
    700: #aead06
  ),

  yellow: (
    100: #fef8e6,
    200: #fef1cc,
    300: #fde499,
    400: #fbd459,
    500: #fbca33,
    600: #fabd00,
    700: #c19306
  ),

  orange: (
    100: #fff1e6,
    200: #ffe3cc,
    300: #ffc899,
    400: #ffa659,
    500: #ff9233,
    600: #ff7700,
    700: #c55f06
  ),

  red: (
    100: #fce6ea,
    200: #f9ccd4,
    300: #f499aa,
    400: #ed5974,
    500: #e93355,
    600: #e4002b,
    700: #b00626
  ),

  pink: (
    100: #fdd8e5,
    200: #fdd8e5,
    300: #f8b1cc,
    400: #f088b4,
    500: #e65c9b,
    600: #da1884,
    700: #be0a6e
  ),

  purple: (
    100: #f7e9f3,
    200: #f0d4e8,
    300: #e0aad2,
    400: #cd80bc,
    500: #ba53a6,
    600: #a51890,
    700: #780a68
  ),

  grey: (
    100: #f7f9fa,
    200: #f0f4f6,
    300: #e2e9ed,
    400: #d0dbe2,
    500: #c5d3db,
    600: #b7c9d3,
    700: #8f9ca4
  ),

  grey-2: (
    100: #eff5f7,
    200: #dfeaee,
    300: #c0d6de,
    400: #99bcca,
    500: #82adbe,
    600: #6399ae,
    700: #507888
  ),

  light-blue: (
    100: #ecf6f9,
    200: #daeef4,
    300: #b5dfe8,
    400: #8ecedd,
    500: #60bfd2,
    600: #00aec7,
    700: #038a9d
  ),

  blue: (
    100: #e6f3f6,
    200: #cce6ee,
    300: #99cede,
    400: #59afc9,
    500: #339dbd,
    600: #0085ad,
    700: #066987
  ),

  blue-2: (
    100: #e6eef3,
    200: #ccdde7,
    300: #99bbcf,
    400: #5990b0,
    500: #33779f,
    600: #005587,
    700: #06456b
  ),

  hyperblue: (
    200: #a6e2f9,
    400: #00aeef,
    600: #0d86d3
  ),

) !default;

*/

//  Fonts
// -------------------------------------------------------------------

// font-family are list in _font-face.scss
/*
$roboto: roboto, Helvetica Neue, Helvetica, Arial, sans-serif !default;

$font-family-base:            $roboto !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.6 !default;

$h1-font-size:                28px !default;
$h2-font-size:                24px !default;
$h3-font-size:                20px !default;
$h4-font-size:                14px !default;
$h5-font-size:                13px !default;
$h6-font-size:                12px !default;

$headings-margin-bottom:      (1rem / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              $gray-600 !default;

$lead-font-size:              ($font-size-base * 1.25) !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;
*/

//  Grids
// -------------------------------------------------------------------

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Semantic colors

$color-success: $secondary-green-1-600 !default;
$color-warning: $secondary-yellow-600 !default;
$color-danger: $secondary-red-600 !default;
$color-info: $grayscale-600 !default;

$small-font-size: 80% !default;

//  Components
// -------------------------------------------------------------------

// Buttons

$btn-padding-y: $spacing-rg !default;
$btn-padding-x: $spacing-lg !default;
$btn-padding-y-extra-small: $spacing-xxs !default;
$btn-padding-x-extra-small: $spacing-xs !default;
$btn-padding-y-small: $spacing-sm !default;
$btn-padding-x-small: $spacing-sm !default;
$btn-padding-y-medium: $spacing-rg !default;
$btn-padding-x-medium: $spacing-md !default;
$btn-padding-x-regular: $spacing-rg !default;
$btn-padding-y-large: $spacing-md !default;
$btn-padding-x-large: $spacing-xl !default;
$btn-padding-y-extra-large: 20px !default;
$btn-padding-x-extra-large: $spacing-xxl !default;
$btn-margin-bottom: $spacing-md !default;
$btn-line-height: 1 !default;
$btn-text-color: $grayscale-600 !default;
$btn-bg-color: transparent !default;
$btn-border-color: $grayscale-400 !default;
$btn-text-color-hover: $white !default;
$btn-bg-color-hover: $grayscale-400 !default;
$btn-border-color-hover: $btn-bg-color-hover !default;
$btn-color-focus: $grayscale-700 !default;
$btn-bg-color-focus: transparent !default;
$btn-border-color-focus: $btn-color-focus !default;

$btn-ghost-color: $grayscale-600 !default;
$btn-ghost-color-hover: $grayscale-700 !default;
$btn-ghost-color-disabled: $grayscale-400 !default;

$btn-negative-color: $white !default;
$btn-negative-border-color: rgba($white, 0.4) !default;
$btn-negative-color-focus: $white !default;
$btn-negative-border-color-focus: $white !default;
$btn-negative-color-hover: $color-primary-2 !default;
$btn-negative-bg-hover: $grayscale-200 !default;
$btn-negative-color-hover: $grayscale-700 !default;
$btn-negative-color-disabled: $white !default;

// Button color variants
// ( @class, @background-color, @color, @border-color)
$btn-variants-list: (
  (primary-1, $color-primary-1, $white, $color-primary-1),
  (white, $white, $color-primary-2, $white)
) !default;

// Links

$link-color: $grayscale-600 !default;
$link-color-hover: $grayscale-600 !default;
$link-color-disabled: $grayscale-400 !default;
$link-decoration: underline !default;
$link-decoration-hover: none !default;
$link-decoration-disabled: none !default;

$link-spacing-small: $spacing-sm !default;
$link-spacing-medium: $spacing-md !default;
$link-spacing-large: $spacing-lg !default;
$link-spacing-xlarge: $spacing-xxl !default;
$link-spacing-xxl: $spacing-xxl !default;

// Buttons group

$btn-group-bg-color-active: $grayscale-400 !default;
$btn-group-color-active: $white !default;
$btn-group-color: $grayscale-400 !default;
$btn-group-border-color: $grayscale-400 !default;
$btn-group-bg-color-hover: $grayscale-500 !default;
$btn-group-color-hover: $white !default;
$btn-group-bg-color-active-disabled: $grayscale-300 !default;
$btn-group-color-active-disabled: $grayscale-200 !default;
$btn-group-border-color-disabled: $grayscale-300 !default;
$btn-group-color-disabled: $grayscale-300 !default;

$btn-group-bg-color-active-dark: $gray_300 !default;
$btn-group-color-active-dark: $white !default;
$btn-group-color-dark: $gray_300 !default;
$btn-group-border-color-dark: $gray_300 !default;
$btn-group-bg-color-hover-dark: $gray_200 !default;
$btn-group-color-hover-dark: $white !default;
$btn-group-bg-color-active-disabled-dark: $gray_400 !default;
$btn-group-color-active-disabled-dark: $grayscale_200 !default;
$btn-group-border-color-disabled-dark: $gray_400 !default;
$btn-group-color-disabled-dark: $gray_400 !default;

$btn-group-bg-color-active-primary: $color-primary-1 !default;
$btn-group-color-active-primary: $white !default;
$btn-group-color-primary: $color-primary-1 !default;
$btn-group-border-color-primary: $color-primary-1 !default;
$btn-group-bg-color-hover-primary: $secondary-blue-400 !default;
$btn-group-color-hover-primary: $white !default;
$btn-group-border-color-hover-primary: $secondary-blue-400 !default;
$btn-group-bg-color-active-disabled-primary: $primary-color-4-300 !default;
$btn-group-color-active-disabled-primary: $white !default;
$btn-group-border-color-disabled-primary: $primary-color-4-300 !default;
$btn-group-color-disabled-primary: $primary-color-4-300 !default;

$btn-group-bg-color-active-light: $grayscale-400 !default;
$btn-group-color-active-light: $white !default;
$btn-group-color-light: $grayscale-400 !default;
$btn-group-border-color-light: $grayscale-400 !default;
$btn-group-bg-color-hover-light: $grayscale-300 !default;
$btn-group-color-hover-light: $white !default;
$btn-group-border-color-hover-light: $grayscale-300 !default;
$btn-group-bg-color-active-disabled-light: $grayscale-300 !default;
$btn-group-color-active-disabled-light: $grayscale-200 !default;
$btn-group-border-color-disabled-light: $grayscale-400 !default;
$btn-group-color-disabled-light: $grayscale-300 !default;

$btn-group-padding-small: $spacing-xs !default;
$btn-group-padding-large: $spacing-lg !default;

//  Header
// -------------------------------------------------------------------

$header-bg: $primary-color-2-700 !default;
$header-name: $white;

$header-spacing-xsmall: $spacing-xs !default;
$header-spacing-base: $spacing-sm !default;
$header-spacing-regular: $spacing-rg !default;
$header-spacing-medium: $spacing-md !default;
$header-spacing-large: $spacing-lg !default;
$header-spacing-xlarge: $spacing-xl !default;

//  Forms
// -------------------------------------------------------------------

// Label

$label-color: $grayscale-600 !default;
$label-action-color: $grayscale-600 !default;
$label-radio-color: $gray-600 !default;
$label-radio-bg-checked: $color-primary-1 !default;
$label-radio-bg-disabled: $gray-200 !default;
$label-checkbox-bg-checked: $color-primary-1 !default;
$label-checkbox-bg-disabled: $grayscale-200 !default;
$label-mandatory: $secondary-red-600 !default;
$label-strong-color: $gray-600 !default;

// Textfield

$input-bg: $white !default;
$input-border-color: $grayscale-400 !default;
$input-color: $gray-600 !default;
$input-border-radius: 0 !default;
$input-placeholder-color: $grayscale-300 !default;

$input-border-color-hover: $grayscale-600 !default;
$input-border-color-focus: $grayscale-700 !default;

$input-color-disabled: $grayscale-300 !default;
$input-border-color-disabled: $grayscale-200 !default;
$input-bg-disabled: $grayscale-100 !default;

$input-bg-dark: transparent !default;
$input-border-color-dark: $gray-300 !default;
$input-color-dark: $gray-300 !default;
// $input-border-radius-dark:     0 !default;
$input-placeholder-color-dark: $gray-300 !default;
$input-icon-color: $grayscale-600 !default;

// Form-group

$form-group-margin-xxsmall: $spacing-xxs !default;
$form-group-margin-xsmall: $spacing-xs !default;
$form-group-margin-small: $spacing-sm !default;
$form-group-margin-regular: $spacing-rg !default;
$form-group-margin-medium: $spacing-md !default;
$form-group-margin-large: $spacing-lg !default;
$form-group-margin-xlarge: $spacing-xl !default;

// Toggles

$toggles-bg-off: $grayscale-300 !default;
$toggles-bg-on: $color-primary-1 !default;
$toggles-bg-off-dark: $gray-700 !default;
$toggles-check-off-dark: $gray-400 !default;

//  Dropdown
// -------------------------------------------------------------------

$dropdown-icon-color: $grayscale-400;
$dropdown-icon-color-hover: $grayscale-700;
$dropdown-option-bg: $white;
$dropdown-option-border: #c0c0c0;
$dropdown-option-color: $gray-600;
$dropdown-option-color-hover: $white;
$dropdown-option-bg-hover: $grayscale-200;

$dropdown-padding-large: $spacing-xl !default;
$dropdown-padding-medium: $spacing-rg !default;
$dropdown-padding-small: $spacing-xs !default;

//  Table
// -------------------------------------------------------------------

$table-thead-bg: $white !default;
$table-tbody-bg: $white !default;
$table-tr-odd-bg: $secondary-blue-100 !default;
$table-td-color: $gray-600 !default;
$table-row-selected-color: #99dee8 !default;
$table-th-color: $black !default;
$table-td-color-gray: $gray-300 !default;
$table-td-color-enhance: $primary-color-4-600 !default;
$table-th-sorting: $grayscale-600 !default;

//  Banners
// -------------------------------------------------------------------

$banners-variants-list: (
  (success, $color-success, $white, check_circle),
  (warning, $color-warning, $white, error),
  (error, $color-danger, $white, cancel),
  (info, $color-info, $white, info)
) !default;

$banners-name-class: success, warning, error, info;

$banners-border-radius: 0 !default;

$banners-padding-medium: $spacing-rg !default;
$banners-padding-large: $spacing-xxl !default;
$banners-margin-bottom: $spacing-md !default;
$banners-title-left-spacing: $spacing-xs !default;

//  Notification
// -------------------------------------------------------------------

$notifications-variants-list: (
  (success, $color-success),
  (warning, $color-warning),
  (error, $color-danger),
  (info, $color-info)
) !default;

$notifications-bg: $gray-600 !default;
$notifications-color: $white !default;
$notifications-border-radius: 0 !default;

$notifications-text-margin-top: $spacing-sm !default;
$notifications-padding-medium: $spacing-md !default;
$notifications-padding-large: $spacing-xxl !default;

//  Tooltips
// -------------------------------------------------------------------

$tooltip-bg: $grayscale-700 !default;
$tooltip-color: $white !default;

$tooltip-padding-medium: $spacing-md !default;

//  Progressbar
// -------------------------------------------------------------------

$progressbar-bg-color: $grayscale-200 !default;
$progressbar-indicator-bg-color: $color-primary-1 !default;
$progessbar-label-color: $grayscale-600 !default;
$progressbar-value-color: $gray-600 !default;
$progressbar-container-bg-color: $grayscale-200 !default;

$progressbar-label-margin-bottom: $spacing-xs !default;
$progressbar-value-padding-right: $spacing-xl !default;

//  Cards
// -------------------------------------------------------------------

$card-bg-color: $white !default;
$card-title-color: $gray-600 !default;
$card-border-color: $grayscale-300 !default;
$card-border-color-hover: $grayscale-300 !default;
$card-border-color-focus: $grayscale-600 !default;
$card-shadow-color-hover: rgba($grayscale-700, 0.25) !default;
$card-shadow-color-focus: rgba($grayscale-700, 0.25) !default;
$card-footer-background-color: rgba($grayscale-100, 0.5) !default;

$card-padding-small: $spacing-sm !default;
$card-padding-regular: $spacing-md !default;
$card-padding-medium: $spacing-lg !default;
$card-picture-margin-bottom: $spacing-lg !default;
$card-footer-margin-top: $spacing-rg !default;
$card-title-margin-bottom: $spacing-md !default;

// Card color variants
// ( @class, @background-color, @color)
$card-bg-color-list: (
  ("", "", ""),
  (color, $primary-color-4-600, $white, $secondary-light-blue-700)
) !default;
$card-icon-header-color: $grayscale-600 !default;

//  Tabs
// -------------------------------------------------------------------

$tab-color: $gray-600 !default;
$tab-color-active: $gray-600 !default;
$tab-color-inactive: $gray-300 !default;
$tab-border-color: $gray-300 !default;
$tab-border-color-active: $color-primary-1 !default;

$tab-color-secondary: $grayscale-600 !default;
$tab-color-active-secondary: $grayscale-600 !default;
$tab-color-inactive-secondary: $grayscale-400 !default;
$tab-border-color-secondary: $grayscale-400 !default;
$tab-border-color-active-secondary: $grayscale-600 !default;

$tab-color-dark: $white !default;
$tab-border-color-dark: $gray-500 !default;
$tab-border-color-active-dark: $white !default;

$tabs-item-margin-rightLeft: $spacing-lg !default;
$tabs-item-margin-bottom: $spacing-xxs !default;
$tabs-link-padding-topBotton: $spacing-sm !default;

//  Spinner
// -------------------------------------------------------------------

$spinner-size-small: 20 !default;
$spinner-size-medium: 40 !default;

$spinner-label: $grayscale-400 !default;

$spinner-padding-medium: $spacing-md !default;
$spinner-label-margin-top: $spacing-sm !default;

//  Range slider
// -------------------------------------------------------------------
$range-slider-bg: $grayscale-200 !default;
$range-slider-handle-bg: $grayscale-600 !default;
$range-slider-fill-bg: $color-primary-1 !default;
$range-slider-item-color: $grayscale-400 !default;
$range-slider-item-selected-color: $grayscale-600 !default;

$range-slider-margin-large: $spacing-lg !default;
$range-slider-margin-small: $spacing-sm !default;

//  Dots
// -------------------------------------------------------------------

$dots-loading-bg: $color-primary-1 !default;

//  Datepicker
// -------------------------------------------------------------------

$datepicker-color: $grayscale-600 !default;
$datepicker-header-color: $grayscale-600 !default;
$datepicker-day-nav-color: $grayscale-600 !default;
$datepicker-day-nav-border-color: $grayscale-400 !default;
$datepicker-day-nav-color-hover: $grayscale-600 !default;
$datepicker-day-nav-border-color-hover: $grayscale-700 !default;
$datepicker-day-color: $grayscale-300 !default;
$datepicker-day-color-hover: $grayscale-600 !default;
$datepicker-day-color-desactivate: $grayscale-300 !default;
$datepicker-day-color-selected: $white !default;
$datepicker-day-bg-selected: $color-primary-1 !default;
$datepicker-day-bg-current: $grayscale-300 !default;
$datepicker-day-bg-hover: $grayscale-200 !default;

$datepicker-padding-small: $spacing-sm !default;
$datepicker-padding-regular: $spacing-rg !default;
$datepicker-padding-medium: $spacing-md !default;
$datepicker-padding-large: $spacing-lg !default;
$datepicker-padding-xlarge: $spacing-xl !default;

//  Hours picker
// -------------------------------------------------------------------
$hourspicker-btn-color: $grayscale-600 !default;
$hourspicker-hours-color: $grayscale-600 !default;
$hourspicker-navigation-color: $grayscale-400 !default;
$hourspicker-navigation-border-color: $grayscale-300 !default;

//  Modal
// -------------------------------------------------------------------

$modal-color: $gray-600 !default;
$modal-title-color: $gray-600 !default;
$modal-footer-background-color: rgba($grayscale-100, 0.5) !default;

$modal-padding-xsmall: $spacing-xs !default;
$modal-padding-small: $spacing-sm !default;
$modal-padding-regular: $spacing-rg !default;
$modal-padding-medium: $spacing-md !default;
$modal-padding-large: $spacing-lg !default;
$modal-padding-xlarge: $spacing-xl !default;

//  Filter
// -------------------------------------------------------------------

$filter-color: $grayscale-500 !default;
$filter-border-color: $grayscale-500 !default;
$filter-bg-color: transparent !default;
$filter-color-hover: $grayscale-600 !default;
$filter-border-color-hover: $grayscale-600 !default;
$filter-color-active: $white !default;
$filter-border-color-active: $color-primary-1 !default;
$filter-bg-color-active: $color-primary-1 !default;
$filter-color-hover-active: $white !default;
$filter-border-color-hover-active: $primary-color-1-700 !default;

$filter-padding-small: $spacing-sm !default;
$filter-padding-medium: $spacing-md !default;
$filter-padding-large: $spacing-lg !default;
$filter-padding-xlarge: $spacing-xl !default;

//  Steppers
// -------------------------------------------------------------------

$steppers-color: $grayscale-300 !default;
$steppers-border-color: $grayscale-300 !default;
$steppers-done-label-color: $gray-600 !default;
$steppers-done-color: $white !default;
$steppers-done-border-color: $color-primary-1 !default;
$steppers-done-bg-color: $color-primary-1 !default;
$steppers-current-color: $white !default;
$steppers-current-border-color: $color-primary-3 !default;
$steppers-current-bg-color: $color-primary-3 !default;

$steppers-item-padding-left: $spacing-xxl !default;
$steppers-v-label-margin-top: $spacing-md !default;
$steppers-h-label-margin-top: $spacing-sm !default;

//  Pagination
// -------------------------------------------------------------------

$pagination-color: $grayscale-600 !default;
$pagination-legend-color: $grayscale-600 !default;
$pagination-color-hover: $grayscale-700 !default;
$pagination-color-active: $white !default;
$pagination-color-disabled: $grayscale-400 !default;

$pagination-bg-hover: $grayscale-200 !default;
$pagination-bg-active: $color-primary-1 !default;

$pagination-container-margin-topBottom: $spacing-lg !default;
$pagination-item-margin-leftRight: $spacing-xxs !default;
$pagination-link-padding-topBottom: $spacing-xxs !default;
$pagination-link-padding-leftRight: $spacing-sm !default;
$pagination-legend-margin-top: $spacing-xs !default;
$pagination-form-group-margin-right: $spacing-sm !default;

//  Chips
// --------------------------------------------------------------------

$chips-bg: $grayscale-100 !default;
$chips-bg-hover: $secondary-blue-300 !default;
$chips-color: $grayscale-700 !default;
$chips-delete-color: $grayscale-600 !default;

$chips-bg-primary: $color-primary-1 !default;
$chips-color-primary: $white !default;
$chips-delete-primary: $grayscale-100 !default;

$chips-bg-secondary: $grayscale-600 !default;
$chips-color-secondary: $white !default;
$chips-delete-secondary: $grayscale-100 !default;

$chips-padding-xsmall: $spacing-xs !default;
$chips-padding-small: $spacing-sm !default;
$chips-padding-large: $spacing-lg !default;
$chips-border-radius: 12px;
$chips-max-height: 24px;
$chips-dropdown-padding-right: $spacing-lg !default;
$chips-more-margin-right: $spacing-xs !default;

//  Flag
// -------------------------------------------------------------------

$flag-color: $white !default;
$flag-bg: $grayscale-600 !default;
$flag-label-color: $grayscale-500 !default;
// Flag color variants
// ( @class, @background-color, @color)
$flag-bg-color-list: (
  (primary-1, $color-primary-1, $white),
  (danger, $color-danger, $white),
  (warning, $color-warning, $white)
) !default;

$flag-padding-medium: $spacing-sm !default;
$flag-label-margin-right: $spacing-rg !default;

//  Accordion
// -------------------------------------------------------------------

$accordion-bg: $white !default;
$accordion-border-color: $grayscale-400 !default;
$accordion-button-more: $grayscale-600 !default;
$accordion-color: $gray-600 !default;

$accordion-padding-xsmall: $spacing-xs !default;
$accordion-padding-small: $spacing-sm !default;
$accordion-padding-medium: $spacing-rg !default;
$accordion-padding-large: $spacing-xxl !default;

//  Scroll top
// -------------------------------------------------------------------

$scroll-top-bg: $grayscale-600 !default;

$scroll-top-padding-xsmall: $spacing-xxs !default;
$scroll-top-padding-small: $spacing-xs !default;
$scroll-top-padding-medium: $spacing-sm !default;

//  Scrollbar
// -------------------------------------------------------------------

$scrollbar-bg: $grayscale-600 !default;
$scrollbar-bg-dark: $white !default;

//  Incrementer
// -------------------------------------------------------------------
$incrementer-button-color: $grayscale-600 !default;
$incrementer-button-color-disabled: $grayscale-400 !default;

$incrementer-padding-small: $spacing-xs !default;
$incrementer-padding-medium: $spacing-rg !default;

//  Avatar
// -------------------------------------------------------------------

$avatar-bg-color: $grayscale-400 !default;
// Status color variants
// ( @class, @background-color)
$avatar-status-color-list: (
  (available, $color-success),
  (away, $color-warning),
  (not-disturb, $color-danger)
) !default;

//  Breadcrumb
// -------------------------------------------------------------------

$breadcrumb-color: $grayscale-500 !default;
$breadcrumb-color-hover: $grayscale-700 !default;

$breadcrumb-margin-large: $spacing-xxl !default;
$breadcrumb-margin-medium: $spacing-lg !default;

//  Comments
// -------------------------------------------------------------------

$comment-author-color: $grayscale-500 !default;
$comment-border-color: $grayscale-200 !default;

$comment-spacing-small: $spacing-xs !default;
$comment-spacing-regular: $spacing-sm !default;
$comment-spacing-medium: $spacing-rg !default;
$comment-spacing-large: $spacing-lg !default;

//  Overflow menu
// -------------------------------------------------------------------

$overflow-menu-bg: $white !default;
$overflow-menu-hr-bg: $grayscale-200 !default;
$overflow-menu-item-color: $grayscale-600 !default;
$overflow-menu-item-color-hover: $grayscale-200 !default;

$overflow-menu-padding-small: $spacing-xs !default;
$overflow-menu-padding-regular: $spacing-sm !default;
$overflow-menu-padding-base: $spacing-md !default;
$overflow-menu-padding-medium: $spacing-rg !default;
$overflow-menu-padding-large: $spacing-lg !default;
$overflow-menu-padding-xlarge: $spacing-xxl !default;

//  List
// -------------------------------------------------------------------

$list-name-color: $gray-600 !default;
$list-desc-color: $grayscale-400 !default;
$list-border-color: $grayscale-200 !default;
$list-icon-color: $grayscale-600 !default;
$list-background-color: $secondary-light-blue-100 !default;

//  Autosave
// -------------------------------------------------------------------

$autosave-icon-progress-color: $grayscale-600 !default;
$autosave-label-color: $grayscale-600 !default;
$autosave-icon-saved-color: $secondary-green-1-600 !default;

$autosave-margin-xsmall: $spacing-xs !default;
$autosave-padding-medium: $spacing-md !default;

//  Calendar
// -------------------------------------------------------------------

$calendar-hour-color: $grayscale-700 !default;
$calendar-event-color: $grayscale-700 !default;
$calendar-event-background-color: $secondary-blue-100 !default;
$calendar-event-background-color-hover: $secondary-blue-400 !default;
$calendar-marker-color: $color-primary-1 !default;
$calendar-timeline-color: $grayscale-200 !default;

$calendar-line-height: $spacing-xl !default;
$calendar-padding-regular: $spacing-sm !default;

//  Transfert listbox
// -------------------------------------------------------------------
$transfert-listbox-padding-xsmall: $spacing-xxs !default;
$transfert-listbox-padding-small: $spacing-xs !default;
$transfert-listbox-padding-base: $spacing-sm !default;
$transfert-listbox-padding-medium: $spacing-md !default;
$transfert-listbox-padding-large: $spacing-lg !default;

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Colors
//
// The colors of UI.
//
// Weight: -10
//
// Style guide: Colors

// Primary Colors
//
// The primary colors of Skywise.
//
// $color-primary-1 - #0085AD; Cerulean Blue
// $color-primary-2 - #004b7c; Orient Blue
// $color-primary-3 - #001e51; Prussian Blue
// $color-primary-4 - #588ea4; Horizon Grey
// $color-primary-5 - #aec2cd; Heather Grey
//
// Weight: -10
//
// Style guide: Colors.Primary

// Gray scale
//
// $grayscale-100 - #eff1f4;
// $grayscale-200 - #e0e3e9;
// $grayscale-300 - #c1c7d3;
// $grayscale-400 - #9aa5b8;
// $grayscale-500 - #8390a7;
// $grayscale-600 - #647592;
// $grayscale-700 - #505d73;
//
// Weight: -9
//
// Style guide: Colors.grey

// UI Color
//
// Colors have 7 variations ranked from 100 to 700.
// * 700 is the darkness color and 100 the lightness
// * 600 is the main color in the palette
//
// To use color, use `secondary-color(@color-name, @contrast)` mixin.
//
// example: `@include secondary-color($orange, 200);`
//
// $green-1 - #03b075;
// $green-2 - #84bd00;
// $green-3 - #e1e000;
// $yellow - #fabd00;
// $orange - #ff7700;
// $red - #e4002b;
// $pink - #da1884;
// $purple - #a51890;
// $grey - #b7c9d3;
// $grey-2 - #6399ae;
// $light-blue - #00aec7;
// $blue - #0085ad;
// $blue-2 - #005587;
//
// Weight: -8
//
// Style guide: Colors.Colors
