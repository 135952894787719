.form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.error {
  border: 1px solid red;
  margin: 25px 0;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}
