/**
 * Toggles
 *
 */

.ds-toggles {
  display: inline-block;
  position: relative;
  input {
    z-index: 2;
    opacity: 0;
    position: absolute;
    left: 0;
    top: -2px;
    min-width: 1em;
    width: 42px;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    &:checked {
      & ~ .ds-label-wrapper {
        &::before {
          background-color: $toggles-bg-on;
        }
        & label {
          &::before {
            left: 20px;
            box-shadow: 1px 2px 0 rgba(#000, 0.25);
            background-color: $white;
          }
        }
      }
    }
  }
}

.ds-label-wrapper {
  position: relative;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 60px;
    width: 42px;
    height: 24px;
    box-sizing: unset;
    background: $toggles-bg-off;
  }
  label {
    margin-bottom: 0;
    text-indent: 50px;
    color: $label-radio-color;
    &::before {
      content: "";
      z-index: 0;
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: $white;
      transition: 0.25s all;
    }
  }
}

// Dark version

.ds-toggles--dark {
  .ds-label-wrapper {
    &::before {
      background-color: $toggles-bg-off-dark;
    }
    label {
      color: $white;
      &::before {
        background-color: $toggles-check-off-dark;
      }
    }
  }
}

// Medium version

.ds-toggles--medium {
  input {
    width: 35px;
    &:checked {
      & ~ .ds-label-wrapper {
        & label {
          &::before {
            left: 17px;
          }
        }
      }
    }
  }
  .ds-label-wrapper {
    &::before {
      width: 35px;
      height: 20px;
    }
    label {
      position: relative;
      top: -2px;
      text-indent: 45px;
      &::before {
        top: 4px;
        left: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

// Small version

.ds-toggles--small {
  input {
    width: 28px;
    &:checked {
      & ~ .ds-label-wrapper {
        & label {
          &::before {
            left: 14px;
          }
        }
      }
    }
  }
  .ds-label-wrapper {
    &::before {
      width: 28px;
      height: 16px;
    }
    label {
      position: relative;
      top: -2px;
      text-indent: 37px;
      &::before {
        top: 4px;
        left: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Toggles
//
// Allow a selection to be turned on or off. Toggles take on the same visual properties of the checkbox button.
//
//
// Markup: ../../templates/partials/components/toggles/toggles.hbs
//
// Style guide: Toggles

// Size
//
// Use different size of toggles by adding `.ds-toggles--medium` or `.ds-toggles--small` classes on `ds-form-group` container.
//
// Markup: ../../templates/partials/components/toggles/toggles-size.hbs
//
// Weight: -10
//
// Style guide: Toggles.Size

// Dark background
//
// For a better contrast on dark background add `.ds-toggles--dark` class on `ds-form-group` element.
//
// Markup: ../../templates/partials/components/toggles/toggles-dark.hbs
//
// Weight: -9
//
// Style guide: Toggles.Dark
