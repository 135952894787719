/**
 * Form layout
 *
 *
 */

.ds-form-group {
  position: relative;
  margin-bottom: $form-group-margin-large;
  .ds-checkbox,
  .ds-radio {
    margin-bottom: $form-group-margin-regular;
  }
}

.ds-form-layout {
  display: flex;
  align-items: flex-end;
  margin-left: -$form-group-margin-large;
  margin-bottom: $form-group-margin-large;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-bottom: 0;
    align-items: normal;
  }
  .ds-form-group {
    flex: 1;
    margin-left: $form-group-margin-large;
    margin-bottom: 0;
    max-width: calc(100% - #{$form-group-margin-large});
    @include media-breakpoint-down(sm) {
      margin-bottom: $form-group-margin-medium;
    }
  }
}

.ds-form-group--inline {
  margin-bottom: $form-group-margin-large;
  & > .ds-radio,
  & > .ds-checkbox {
    display: inline-flex;
    margin-right: $form-group-margin-xlarge;
  }
}

.ds-form-group-submit {
  margin-top: $form-group-margin-large;
  text-align: right;
  button {
    margin-left: $form-group-margin-medium;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: $form-group-margin-xsmall;
      margin-left: 0;
    }
  }
}

.ds-label {
  display: block;
  &.ds-col-label {
    padding-top: $form-group-margin-small;
  }
}

.ds-label--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.ds-label--info {
  align-self: flex-end;
  border: 0;
  padding: 0;
  font-size: 12px;
  color: $label-action-color;
  cursor: pointer;
  background: none;
}

button.ds-label--info {
  font-size: 14px;
}

//  Help text
// -------------------------------------------------------------------

.ds-form-helptext {
  margin-top: $form-group-margin-xxsmall;
  font-size: 12px;
  color: $grayscale-500;
  button {
    padding: 0;
    color: $grayscale-500;
  }
  .ds-form-helptext__picto {
    position: relative;
    bottom: -3px;
    font-size: 14px;
  }
}

.ds-form-counter {
  @extend .ds-form-helptext;
  text-align: right;
}
