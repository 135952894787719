.ds-flag {
  display: inline-block;
  padding: 3px $flag-padding-medium 4px;
  line-height: 16px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: $flag-color;
  background: $flag-bg;
}

.ds-flag {
  @each $name, $background-color, $color in $flag-bg-color-list {
    &--#{$name} {
      color: $color;
      background-color: $background-color;
    }
  }
}

.ds-flag-label {
  margin-right: $flag-label-margin-right;
  font-size: 13px;
  font-weight: 400;
  color: $flag-label-color;
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Flag
//
// The flag is a basic component that displays a word.
//
// Markup: ../../templates/partials/components/flags/flags.hbs
//
// Style guide: Flag

// With label
//
// Add a `span` with `.ds-flag-label` class before flag to add a label. Don't forget to add an `aria-labelledby` attribute to link the label and the flag.
//
// Markup: ../../templates/partials/components/flags/flags-with-label.hbs
//
// Style guide: Flag.Label
