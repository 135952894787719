.header {
  background-color: #f0f0f0;
  padding: 0 20px;
  display: flex;
  align-items: flex-end;
}

.linkContent {
  min-height: 37px;
}

.link {
  padding: 10px 30px;
  border-bottom: 4px solid transparent;
  text-decoration: none;
}

.link.selected {
  border-bottom: 4px solid #0085ad;
}
