.image {
  height: 35vh;
  width: 100%;
  background-image: url("../../../statics/images/screening-country.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 150px;
}

.body .form {
  margin-top: 15px;
}

.body .row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.body .row .input {
  padding: 0 12px;
}

.body .row .input .searchButton {
  background-color: #99a5b8;
  height: 33px;
  width: 34px;
  margin-top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body .eccnError {
  color: red;
  padding: 4em 0 0 0.5em;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 868px) {
  .image {
    display: none;
  }
}
