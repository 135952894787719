.ds-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $btn-padding-y $btn-padding-x;
  margin: 0;
  border: 1px solid $btn-border-color;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  line-height: $btn-line-height;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: $btn-text-color;
  background-color: $btn-bg-color;
  transition: 0.25s;

  &:focus {
    outline: 1px dotted rgba($btn-border-color-focus, 0.25);
    text-decoration: none;
    color: $btn-color-focus;
    border-color: $btn-border-color-focus;
    background: $btn-bg-color-focus;
  }

  &.ds-button--disabled,
  &:disabled {
    opacity: 0.35;
    color: $btn-text-color;
    background-color: $btn-bg-color;
    pointer-events: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
    outline: 0;
    color: $btn-text-color-hover;
    border-color: $btn-bg-color-hover;
    background-color: $btn-bg-color-hover;
  }

  &.ds-button--extra-small {
    min-width: 64px;
    padding: $btn-padding-y-extra-small $btn-padding-x-extra-small
      $btn-padding-y-extra-small + 1;
    font-size: 12px;
    min-height: 24px;
  }

  &.ds-button--small {
    min-width: 64px;
    padding: $btn-padding-y-small $btn-padding-x-small $btn-padding-y-small + 1;
    font-size: 13px;
    min-height: 24px;
  }

  &.ds-button--medium {
    padding: $btn-padding-y-medium $btn-padding-x-medium;
    font-size: 14px;
  }

  &.ds-button--large {
    min-width: 64px;
    padding: $btn-padding-y-large $btn-padding-x-large $btn-padding-y-large + 1;
    font-size: 15px;
    min-height: 24px;
  }

  &.ds-button--extra-large {
    min-width: 64px;
    padding: $btn-padding-y-extra-large $btn-padding-x-extra-large
      $btn-padding-y-extra-large + 1;
    font-size: 16px;
    min-height: 24px;
  }
}

.ds-button--negative {
  color: $btn-negative-color;
  border-color: $btn-negative-border-color;
  background-color: transparent;

  &:focus {
    color: $btn-negative-color-focus;
    border-color: $btn-negative-border-color-focus;
  }

  &.ds-button--disabled,
  &:disabled {
    opacity: 0.35;
    color: $btn-negative-color-disabled;
    border-color: $btn-negative-color-disabled;
    pointer-events: none;
  }

  &:hover,
  &:active {
    color: $btn-negative-color-hover;
    border-color: $white;
    background: $white;
  }
}

// Button with other color

.ds-button {
  @each $name, $background-color, $color, $border-color in $btn-variants-list {
    &--#{$name} {
      background-color: $background-color;
      color: $color;
      border-color: $border-color;

      &:focus {
        color: $background-color;
        border-color: $border-color;
      }

      &.ds-button--disabled,
      &:disabled {
        color: $color;
        background-color: $background-color;
        pointer-events: none;
      }

      &:hover,
      &:active {
        color: $color;
        @if ($name == white) {
          border-color: $grayscale-200;
          background-color: $grayscale-200;
        } @else {
          border-color: darken($border-color, 13%);
          background-color: darken($background-color, 13%);
        }
      }
    }
  }
}

.ds-button--with-icon {
  line-height: 1em;

  & [class^="ds-icon"],
  svg {
    font-size: 1.5em;
    vertical-align: bottom;
  }

  &.ds-button--success {
    // padding: $btn-padding-y-small $btn-padding-y-small $btn-padding-y-small $btn-padding-x;
    color: $white;
    border-color: $color-success;
    background: $color-success;
  }
}

.ds-button--link {
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: $link-decoration;
  font-size: 16px;
  cursor: pointer;
  color: $link-color;
  background: none;
  -webkit-text-decoration-skip: objects;

  &:hover {
    color: $link-color-hover;
    text-decoration: $link-decoration-hover;
  }

  &:focus {
    outline: 1px dotted $grayscale-500;
  }

  &.ds-button--disabled,
  &:disabled {
    color: $link-color-disabled;
    text-decoration: $link-decoration-disabled;
    pointer-events: none;
  }
}

.ds-button--icon {
  @extend .ds-button--link;
  text-decoration: none;

  [class^="ds-icon"] {
    text-decoration: none;
    font-size: 17px;

    &:hover,
    &:focus {
      color: $grayscale-700;
    }
  }
}

.ds-button--close {
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: 0;
}

.ds-button--ghost {
  display: inline-block;
  padding: $btn-padding-y $btn-padding-x-regular $btn-padding-y
    $btn-padding-x-large;
  margin: 0;
  font-size: 14px;
  color: $btn-ghost-color;
  text-decoration: none;
  transition: 0.25s;
  border: none;

  &:hover,
  &:focus {
    color: $btn-ghost-color-hover;
  }

  &.ds-button--disabled,
  &:disabled {
    color: $btn-ghost-color-disabled;
    pointer-events: none;
  }

  [class^="ds-icon"],
  svg {
    position: absolute;
    margin-top: -1px;
    margin-left: -$btn-padding-x;
    font-size: 16px;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Button
//
// Button styles can be applied to anything with the `.ds-button` class.
//
// Style guide: Button

// Basics
//
// Buttons can be used for many purposes, it's important to use the right tag.
// * Use the `<a>` tag if the button is a link to another page, or a link to an anchor within a page.
// * Use the `<button>` tag if the button performs an action that changes something on the current page. <br>
//
// Add the attribute `type="button"` to `<button>` elements, unless the button submits a form, in which case use `type="submit"`.
//
// Markup: ../../templates/partials/components/button/button.hbs
//
// Weight: -14
//
// Style guide: Button.Basic

// Sizing
//
// Add `.ds-button--extra-small`, `.ds-button--small`, `.ds-button--medium`, `.ds-button--large` or `.ds-button--extra-large` class to `.ds-button` for additional sizes.
//
//
// Weight: -13
//
// Style guide: Button.Sizing

// Extra small size
//
// Markup: ../../templates/partials/components/button/button-sizing-extra-small.hbs
//
// Weight: -12
//
// Style guide: Button.Sizing.Extra-small

// Small size
//
// Markup: ../../templates/partials/components/button/button-sizing-small.hbs
//
// Weight: -11
//
// Style guide: Button.Sizing.Small

// Medium size
//
// Markup: ../../templates/partials/components/button/button-sizing-medium.hbs
//
// Weight: -10
//
// Style guide: Button.Sizing.Medium

// Large size
//
// Markup: ../../templates/partials/components/button/button-sizing-large.hbs
//
// Weight: -9
//
// Style guide: Button.Sizing.Large

// Extra large size
//
// Markup: ../../templates/partials/components/button/button-sizing-extra-large.hbs
//
// Weight: -8
//
// Style guide: Button.Sizing.Extra-large

// Disabled state
//
// For `<button>` elements, add the `disabled` attribute to both disable and style it. <br> `<a>` element doesn’t support the `disabled` attribute, add the `.ds-button--disabled` class to make it visually appear disabled, add also the `aria-disabled="true"` attribute to mark it as disabled for assistive technologies.
//
// Markup: ../../templates/partials/components/button/button-disabled.hbs
//
// Weight: -7
//
// Style guide: Button.Disabled

// Text button
//
// Use `.ds-button--link` to style `<button>` like a simple link. When using button classes on `<a>` elements that are used to trigger in-page feature, add `role="button"` to it.
//
// Markup: ../../templates/partials/components/button/button-link.hbs
//
// Weight: -6
//
// Style guide: Button.Link

// Icon button
//
// Use the `.ds-button--icon` class when an icon button is needed. The icon button is preferably used without a label.
// * for `<button>` element add `aria-label="Label"`.
// * for `<a>` element use `title="Label"` instead.
//
// Markup: ../../templates/partials/components/button/button-icon.hbs
//
// Weight: -5
//
// Style guide: Button.Icon

// Other colors
//
// For a better contrast on dark background add `.ds-button--white` or `.ds-button--negative` classes to `.ds-button`.
//
// Markup: ../../templates/partials/components/button/button-other-color.hbs
//
// Weight: -4
//
// Style guide: Button.Other-color

// With icon
//
// Use a `span` with `ds-icon-*` class and add `.ds-button--with-icon` class to `.ds-button`.
//
// Markup: ../../templates/partials/components/button/button-with-icon.hbs
//
// Weight: -3
//
// Style guide: Button.With-icon

// Ghost button
//
// A ghost button has the same padding-y of a normal button, but it looks like a simple link.
//
// Markup: ../../templates/partials/components/button/button-ghost.hbs
//
// Weight: -2
//
// Style guide: Button.Ghost

// Success button
//
// Wrap `.ds-icon-check` element in a button with four classes :
// * ds-button
// * ds-button--small
// * ds-button--with-icon
// * ds-button--success
//
// Markup: ../../templates/partials/components/button/button-success.hbs
//
// Weight: -1
//
// Style guide: Button.Success
