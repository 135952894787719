.drawerMenu-enter {
  transform: translateX(-100%);
}
.drawerMenu-enter-active {
  transform: translateX(0%);
  transition: all 300ms ease-out;
}
.drawerMenu-exit {
  transform: translateX(0%);
}
.drawerMenu-exit-active {
  transform: translateX(-100%);
  transition: all 300ms ease-out;
}
