.ds-autosave {
  margin: 0 auto;
  text-align: center;
  padding: $autosave-padding-medium;
}
.ds-autosave--progress {
  display: flex;
  justify-content: center;
  align-items: center;
  span[class^="ds-icon"] {
    color: $autosave-icon-progress-color;
    animation-name: rotate;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes rotate {
  0% {
    transform-origin: center;
  }
  100% {
    transform-origin: center;
    transform: rotate(-360deg);
  }
}

.ds-autosave__label {
  margin-left: $autosave-margin-xsmall;
  font-size: 12px;
  text-align: center;
  color: $autosave-label-color;
}

.ds-autosave--saved {
  display: flex;
  justify-content: center;
  align-items: center;
  span[class^="ds-icon"] {
    color: $autosave-icon-saved-color;
  }
}
// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Autosave
//
// There are two states in the saving process : In progress and saved when it is completely saved.
//
// Style guide: AutoSave

// In progress
//
// Autosave is a `<div>` element with the `.ds-autosave` class. Two elements are wrapped :
// * an icon
// * a label `ds-autosave__label`
//
// Markup: ../../templates/partials/components/autosave/autosave-in-progress.hbs
//
// Weight: -10
//
// Style guide: AutoSave.InProgress

// Saved
//
// Add `.ds-autosave--saved` to `.ds-autosave` to change autosave status.
//
// Markup: ../../templates/partials/components/autosave/autosave-saved.hbs
//
// Weight: -9
//
// Style guide: AutoSave.Saved
