.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  min-height: 125vh; /* Prendre au moins la hauteur de la fenêtre */
  overflow-y: auto; /* Permettre le défilement vertical */
}

.body .form {
  margin-top: 15px;
}

.body .row {
  display: flex;
  align-items: center;
}

.body .input {
  padding: 0 12px;
  max-width: 500px;
}
.input.autoComplete {
  margin-top: -24px;
}
