.ds-accordion__item {
  margin-bottom: $accordion-padding-small;
  font-family: inherit;
  font-size: 14px;
  color: $accordion-color;
  border: 1px solid $accordion-border-color;
  background: $accordion-bg;
  .ds-icon-minimize {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in;
  }

  &.ds-accordion__item--active {
    .ds-icon-add {
      opacity: 0;
      visibility: hidden;
    }

    .ds-icon-minimize {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in;
    }
  }
}

.ds-accordion__button {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: $accordion-padding-small $accordion-padding-large
    $accordion-padding-small $accordion-padding-medium;
  text-align: left;

  .ds-icon-add {
    position: absolute;
    top: 6px;
    right: $accordion-padding-medium;
    font-size: 24px;
    line-height: 1;
    color: $accordion-button-more;
    user-select: none;
    transition: all 0.3s ease-out;
  }

  .ds-icon-minimize {
    position: absolute;
    top: 6px;
    right: $accordion-padding-medium;
    font-size: 24px;
    line-height: 1;
    color: $accordion-button-more;
    user-select: none;
  }

  &:focus {
    outline: 0;
  }
}

.ds-accordion__content {
  display: none;
  padding: $accordion-padding-small $accordion-padding-medium;
  font-size: 12px;

  &.ds-accordion__content--show {
    display: block;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Accordion
//
// An accordion allow user to toggle the visibility of content.
//
// Weight: -8
//
// Style guide: Accordion

// Normal
//
// Accordion is a container `.ds-accordion` with two main elements :
// * `.ds-accordion__button` a button which allow to show or hide content.
// * `.ds-accordion__content` : the content section.
//
// When accordion is expanded, add `.ds-accordion__content--show` to `.ds-accordion__content`.
// Use `.ds-accordion__item--active` class on `.ds-accordion__item` when the accordion is expanded.
//
// Markup: ../../templates/partials/components/accordion/accordion.hbs
//
// Weight: -10
//
// Style guide: Accordion.Normal

// List
//
// Accordion can be displayed like a list of button.
//
// Markup: ../../templates/partials/components/accordion/accordion-list.hbs
//
// Weight: -9
//
// Style guide: Accordion.List
