//  Spinner
// --------------------------------------------------------------------

.ds-spinner {
  max-width: 104px;
  margin: 0 auto;
  text-align: center;
  padding: $spinner-padding-medium;
}

.ds-spinner--block {
  background: $progressbar-container-bg-color;
}

.ds-spinner--block-white {
  background: $white;
}

.ds-spinner--block-alone {
  max-width: 72px;
}

.ds-spinner__loading {
  margin: 0 auto;
  width: $spinner-size-medium + px;
  height: $spinner-size-medium + px;
  animation-name: ds-spinner-anim;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.ds-spinner__svg {
  display: block;
  margin: 0 auto;
  fill: transparent;
  stroke: $color-primary-1;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-dasharray: 140;
  stroke-dashoffset: 40;
  circle {
    animation: ds-init-stroke 2s cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.ds-dots__label,
.ds-spinner__label {
  display: block;
  margin-top: $spinner-label-margin-top;
  font-size: 13px;
  text-align: center;
  color: $spinner-label;
}

.ds-spinner--small {
  .ds-spinner__svg {
    stroke-width: 8px;
  }
  .ds-spinner__loading {
    width: $spinner-size-small + px;
    height: $spinner-size-small + px;
  }
}

@keyframes ds-init-stroke {
  0% {
    stroke-dashoffset: 140;
  }

  100% {
    stroke-dashoffset: 40;
  }
}

@keyframes ds-spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//  Dots
// --------------------------------------------------------------------

.ds-dots {
  max-width: 300px;
  margin: auto;
  text-align: center;
  .ds-dots__item {
    &:first-child {
      animation: ds-wave 1.3s 0.2s ease infinite;
    }
    &:nth-child(even) {
      animation: ds-wave 1.3s 0.4s ease infinite;
    }
    &:last-child {
      margin-right: 0;
      animation: ds-wave 1.3s 0.5s ease infinite;
    }
  }
}
.ds-dots__loading {
  & > div {
    display: inline-block;
    margin-right: 12px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: $dots-loading-bg;
  }
}

@keyframes ds-wave {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  30% {
    transform: translate(0, -6px);
    opacity: 0.15;
  }
  60% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Loading
//
// Continuous spinners are used when the loading progress cannot be determined. They help to notify users of loading being carried out by the system.
//
// Markup: ../../templates/partials/components/loading/spinner.hbs
//
// Style guide: Spinner

// Small size
//
// With `.ds-spinner--small` you can display a smaller spinner.
//
// Markup: ../../templates/partials/components/loading/spinner-small.hbs
//
// Weight: -10
//
// Style guide: Spinner.Small

// With container
//
// `.ds-spinner--block` or `.ds-spinner--block-white` classes add a background to your spinner. If you don't use a label, precise it with `.ds-spinner--block-alone` (because we need to adapt container padding) .
//
// Markup: ../../templates/partials/components/loading/spinner-container.hbs
//
// Weight: -9
//
// Style guide: Spinner.Container

// Dots
//
// `.ds-spinner--block` or `.ds-spinner--block-white` classes add a background to your spinner. If you don't use a label, precise it with `.ds-spinner--block-alone` (because we need to adapt container padding) .
//
// Markup: ../../templates/partials/components/loading/dots.hbs
//
// Weight: -8
//
// Style guide: Spinner.Dots
