.ds-banner {
  position: relative;
  margin-bottom: $banners-margin-bottom;
  padding: $banners-padding-medium $banners-padding-large
    $banners-padding-medium $banners-padding-medium;
  border-radius: $banners-border-radius;
  font-size: 13px;
  font-weight: 400;

  &::before {
    position: absolute;
    top: 6px;
    left: 12px;
    font-size: 17px;
  }

  .ds-button--close {
    position: absolute;
    top: 7px;
    right: 2px;
    font-size: 16px;

    span {
      color: $white;
    }
  }
}

.ds-banner--center {
  text-align: center;
}

.ds-banner__title {
  display: block;
  margin: -4px 0 0;
  padding-left: 5px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  color: $white;

  [class^="ds-icon"] {
    position: relative;
    bottom: -3px;
    left: -8px;
    font-size: 18px;
  }
}

.ds-banner__text {
  display: block;
  margin: 6px 0 0 26px;
  color: $white;
}

.ds-banner__link {
  color: $white;
  &:hover {
    color: $white;
  }
}

$selector: ();
@each $name in $banners-name-class {
  $selector: $selector unquote(".ds-banner--#{$name}");

  // Add comma if not dealing with the last item of list
  @if $name != nth($banners-name-class, length($banners-name-class)) {
    $selector: $selector unquote(",");
  }
}

// #{$selector} {
//   &::before {
//     @include ds-icon-styles;
//   }
// }

.ds-banner {
  @each $name, $background-color, $color, $icone in $banners-variants-list {
    &--#{$name} {
      color: $color;
      background-color: $background-color;
      //@include ds-icon($icone);
      & .ds-banner__button {
        @extend .ds-button--small;
        color: $color;
        border-color: $white;
        margin: 6px 0 6px 26px;

        &:hover {
          color: $background-color;
          background-color: $white;
        }
      }
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Banners
//
// Provide contextual feedback messages about important changes or persistent conditions.
//
// Style guide: Banners

// Examples
//
// The banner style depends of the message you want to provide. Pay attention to the use of the `role` attribute :
// * `role="alert"` : is used to communicate an important and usually time-sensitive message to the user.
// * `role="status"` : is used for an advisory information to the user that is not important enough to justify an alert.
//
// Markup: ../../templates/partials/components/banner/banner-example.hbs
//
// Weight: -10
//
// Style guide: Banners.Example

// Center message
//
// Use `.ds-banner--center` class on `.ds-banner` container.
//
// Markup: ../../templates/partials/components/banner/banner-center.hbs
//
// Weight: -9
//
// Style guide: Banners.Center

// With text
//
// Message can also contain an additional paragraph with `.ds-banner__text` element.
//
// Markup: ../../templates/partials/components/banner/banner-additional.hbs
//
// Weight: -8
//
// Style guide: Banners.Additional

// With text, links, buttons
//
// You can also add links and buttons using `.ds-banner__link` `.ds-banner__button` classes.
//
// Markup: ../../templates/partials/components/banner/banner-link-button.hbs
//
// Weight: -7
//
// Style guide: Banners.Link-button
