// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .ds-flex#{$infix}-row {
      flex-direction: row !important;
    }
    .ds-flex#{$infix}-column {
      flex-direction: column !important;
    }
    .ds-flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }
    .ds-flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .ds-flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }
    .ds-flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }
    .ds-flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .ds-justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .ds-justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .ds-justify-content#{$infix}-center {
      justify-content: center !important;
    }
    .ds-justify-content#{$infix}-between {
      justify-content: space-between !important;
    }
    .ds-justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .ds-align-items#{$infix}-start {
      align-items: flex-start !important;
    }
    .ds-align-items#{$infix}-end {
      align-items: flex-end !important;
    }
    .ds-align-items#{$infix}-center {
      align-items: center !important;
    }
    .ds-align-items#{$infix}-baseline {
      align-items: baseline !important;
    }
    .ds-align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .ds-align-content#{$infix}-start {
      align-content: flex-start !important;
    }
    .ds-align-content#{$infix}-end {
      align-content: flex-end !important;
    }
    .ds-align-content#{$infix}-center {
      align-content: center !important;
    }
    .ds-align-content#{$infix}-between {
      align-content: space-between !important;
    }
    .ds-align-content#{$infix}-around {
      align-content: space-around !important;
    }
    .ds-align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .ds-align-self#{$infix}-auto {
      align-self: auto !important;
    }
    .ds-align-self#{$infix}-start {
      align-self: flex-start !important;
    }
    .ds-align-self#{$infix}-end {
      align-self: flex-end !important;
    }
    .ds-align-self#{$infix}-center {
      align-self: center !important;
    }
    .ds-align-self#{$infix}-baseline {
      align-self: baseline !important;
    }
    .ds-align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}
