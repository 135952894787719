/* Global Colors */
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$gray-100: rgb(217, 217, 219);
$gray-200: rgb(179, 180, 183);
$gray-300: rgb(143, 146, 149);
$gray-400: rgb(109, 112, 115);
$gray-500: rgb(76, 80, 84);
$gray-600: rgb(46, 50, 55);
$gray-700: rgb(20, 22, 26);
$grayscale-100: rgb(239, 241, 244);
$grayscale-200: rgb(224, 227, 233);
$grayscale-300: rgb(193, 199, 211);
$grayscale-400: rgb(154, 165, 184);
$grayscale-500: rgb(131, 144, 167);
$grayscale-600: rgb(100, 117, 146); // AA for text on white
$grayscale-700: rgb(80, 93, 115); // AA too
$primary-color-1-100: rgb(253, 216, 229);
$primary-color-1-200: rgb(253, 216, 229);
$primary-color-1-300: rgb(248, 177, 204);
$primary-color-1-400: rgb(240, 136, 180);
$primary-color-1-500: rgb(230, 92, 155);
$primary-color-1-600: rgb(218, 24, 132);
$primary-color-1-700: rgb(190, 10, 110);
$primary-color-2-100: rgb(230, 230, 237);
$primary-color-2-200: rgb(205, 205, 220);
$primary-color-2-300: rgb(158, 157, 187);
$primary-color-2-400: rgb(111, 111, 154);
$primary-color-2-500: rgb(64, 70, 122);
$primary-color-2-600: rgb(0, 32, 91);
$primary-color-2-700: rgb(1, 17, 51);
$primary-color-3-100: rgb(228, 228, 233);
$primary-color-3-200: rgb(201, 201, 211);
$primary-color-3-300: rgb(148, 150, 168);
$primary-color-3-400: rgb(99, 101, 127);
$primary-color-3-500: rgb(51, 56, 87);
$primary-color-3-600: rgb(1, 17, 51);
$primary-color-3-700: rgb(1, 10, 31);
$primary-color-4-100: rgb(236, 246, 249);
$primary-color-4-200: rgb(218, 238, 244);
$primary-color-4-300: rgb(181, 223, 232);
$primary-color-4-400: rgb(142, 206, 221);
$primary-color-4-500: rgb(96, 191, 210);
$primary-color-4-600: rgb(0, 174, 199);
$primary-color-4-700: rgb(3, 138, 157);
$primary-color-5-100: rgb(247, 233, 243);
$primary-color-5-200: rgb(240, 212, 232);
$primary-color-5-300: rgb(224, 170, 210);
$primary-color-5-400: rgb(205, 128, 188);
$primary-color-5-500: rgb(186, 83, 166);
$primary-color-5-600: rgb(165, 24, 144);
$primary-color-5-700: rgb(120, 10, 104);
$secondary-green-1-100: rgb(223, 242, 231);
$secondary-green-1-200: rgb(189, 229, 207);
$secondary-green-1-300: rgb(156, 217, 184);
$secondary-green-1-400: rgb(121, 204, 161);
$secondary-green-1-500: rgb(79, 190, 138);
$secondary-green-1-600: rgb(3, 176, 117);
$secondary-green-1-700: rgb(14, 128, 89);
$secondary-green-2-100: rgb(242, 248, 230);
$secondary-green-2-200: rgb(230, 241, 204);
$secondary-green-2-300: rgb(205, 228, 153);
$secondary-green-2-400: rgb(174, 212, 89);
$secondary-green-2-500: rgb(156, 202, 51);
$secondary-green-2-600: rgb(132, 189, 0);
$secondary-green-2-700: rgb(104, 147, 6);
$secondary-green-3-100: rgb(252, 251, 230);
$secondary-green-3-200: rgb(249, 248, 204);
$secondary-green-3-300: rgb(243, 242, 153);
$secondary-green-3-400: rgb(235, 234, 89);
$secondary-green-3-500: rgb(231, 230, 51);
$secondary-green-3-600: rgb(225, 224, 0);
$secondary-green-3-700: rgb(174, 173, 6);
$secondary-yellow-100: rgb(254, 248, 230);
$secondary-yellow-200: rgb(254, 241, 204);
$secondary-yellow-300: rgb(253, 228, 153);
$secondary-yellow-400: rgb(251, 212, 89);
$secondary-yellow-500: rgb(251, 202, 51);
$secondary-yellow-600: rgb(250, 189, 0);
$secondary-yellow-700: rgb(193, 147, 6);
$secondary-orange-100: rgb(255, 241, 230);
$secondary-orange-200: rgb(255, 227, 204);
$secondary-orange-300: rgb(255, 200, 153);
$secondary-orange-400: rgb(255, 166, 89);
$secondary-orange-500: rgb(255, 146, 51);
$secondary-orange-600: rgb(255, 119, 0);
$secondary-orange-700: rgb(197, 95, 6);
$secondary-red-100: rgb(252, 230, 234);
$secondary-red-200: rgb(249, 204, 212);
$secondary-red-300: rgb(244, 153, 170);
$secondary-red-400: rgb(237, 89, 116);
$secondary-red-500: rgb(233, 51, 85);
$secondary-red-600: rgb(228, 0, 43);
$secondary-red-700: rgb(176, 6, 38);
$secondary-pink-100: rgb(253, 216, 229);
$secondary-pink-200: rgb(253, 216, 229);
$secondary-pink-300: rgb(248, 177, 204);
$secondary-pink-400: rgb(240, 136, 180);
$secondary-pink-500: rgb(230, 92, 155);
$secondary-pink-600: rgb(218, 24, 132);
$secondary-pink-700: rgb(190, 10, 110);
$secondary-purple-100: rgb(247, 233, 243);
$secondary-purple-200: rgb(240, 212, 232);
$secondary-purple-300: rgb(224, 170, 210);
$secondary-purple-400: rgb(205, 128, 188);
$secondary-purple-500: rgb(186, 83, 166);
$secondary-purple-600: rgb(165, 24, 144);
$secondary-purple-700: rgb(120, 10, 104);
$secondary-grey-100: rgb(247, 249, 250);
$secondary-grey-200: rgb(240, 244, 246);
$secondary-grey-300: rgb(226, 233, 237);
$secondary-grey-400: rgb(208, 219, 226);
$secondary-grey-500: rgb(197, 211, 219);
$secondary-grey-600: rgb(183, 201, 211);
$secondary-grey-700: rgb(143, 156, 164);
$secondary-grey-2-100: rgb(239, 245, 247);
$secondary-grey-2-200: rgb(223, 234, 238);
$secondary-grey-2-300: rgb(192, 214, 222);
$secondary-grey-2-400: rgb(153, 188, 202);
$secondary-grey-2-500: rgb(130, 173, 190);
$secondary-grey-2-600: rgb(99, 153, 174);
$secondary-grey-2-700: rgb(80, 120, 136);
$secondary-light-blue-100: rgb(236, 246, 249);
$secondary-light-blue-200: rgb(218, 238, 244);
$secondary-light-blue-300: rgb(181, 223, 232);
$secondary-light-blue-400: rgb(142, 206, 221);
$secondary-light-blue-500: rgb(96, 191, 210);
$secondary-light-blue-600: rgb(0, 174, 199);
$secondary-light-blue-700: rgb(3, 138, 157);
$secondary-blue-100: rgb(230, 243, 246);
$secondary-blue-200: rgb(204, 230, 238);
$secondary-blue-300: rgb(153, 206, 222);
$secondary-blue-400: rgb(89, 175, 201);
$secondary-blue-500: rgb(51, 157, 189);
$secondary-blue-600: rgb(0, 133, 173);
$secondary-blue-700: rgb(6, 105, 135);
$secondary-blue-2-100: rgb(230, 238, 243);
$secondary-blue-2-200: rgb(204, 221, 231);
$secondary-blue-2-300: rgb(153, 187, 207);
$secondary-blue-2-400: rgb(89, 144, 176);
$secondary-blue-2-500: rgb(51, 119, 159);
$secondary-blue-2-600: rgb(0, 85, 135);
$secondary-blue-2-700: rgb(6, 69, 107);
$secondary-hyperblue-200: rgb(166, 226, 249);
$secondary-hyperblue-400: rgb(0, 174, 239);
$secondary-hyperblue-600: rgb(13, 134, 211);
$font-family-base:
  Roboto,
  Helvetica Neue,
  Helvetica,
  Arial,
  sans-serif !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$line-height-base: 1.6 !default;
$font-weight-base: 400 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$h1-font-size: 2.75rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.625rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.75rem !default;
$headings-margin-bottom: 0.5rem !default;
$headings-font-family:
  Roboto,
  Helvetica Neue,
  Helvetica,
  Arial,
  sans-serif !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: rgb(46, 50, 55);
$lead-font-size: 1.25rem !default;
$lead-font-weight: 300 !default;
$small-font-size: 80% !default;
/* Base 8px */
$spacing-base: 8px;
/* base 8 x .25 */
$spacing-xxs: 2px;
/* base 8 x .5 */
$spacing-xs: 4px;
/* base 8 x 1 */
$spacing-sm: 8px;
/* base 8 x 1.5 */
$spacing-rg: 12px;
/* base 8 x 2 */
$spacing-md: 16px;
/* base 8 x 3 */
$spacing-lg: 24px;
/* base 8 x 4 */
$spacing-xl: 32px;
/* base 8 x 5 */
$spacing-xxl: 40px;
/* Airbus color theme */
$color-primary-1: rgb(0, 133, 173);
$color-primary-2: rgb(0, 75, 124);
$color-primary-3: rgb(0, 30, 81);
$color-primary-4: rgb(88, 142, 164);
$color-primary-5: rgb(174, 194, 205);
/* z-index options */
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
/* Quickly modify global styling by enabling or disabling optional features. */
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
