.image {
  height: 40vh;
  width: 100%;
  background-image: url("../../../../statics/images/screening-end-user.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 150px;
}

.body .form {
  margin-top: 15px;
}

.body .row {
  display: flex;
  align-items: center;
}

.body .row .input {
  padding: 0 12px;
}

@media screen and (max-width: 868px) {
  .image {
    display: none;
  }
}
