.ds-select-chips {
  @extend .ds-fake-input;
  position: relative;
  padding: $chips-padding-small $chips-padding-small 0;
}

.ds-select-chips__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.ds-select-chips--dropdown {
  border: 0;
  //Dev-mobil padding-right: $chips-dropdown-padding-right;
  .ds-select-chips--show-content & {
    .ds-select-chips__item--more {
      opacity: 0;
      visibility: hidden;
      padding: 0;
      margin: 0;
    }
  }
}

.ds-select-chips__item {
  position: relative;
  display: inline-block;
  padding: $chips-padding-xsmall $chips-padding-small;
  font-size: 12px;
  line-height: 16px;
  max-height: $chips-max-height;
  list-style: none;
  cursor: default;
  color: $chips-color;
  background: $chips-bg;
  border-radius: $chips-border-radius;
  transition: 0.3s all;
  span {
    padding: $chips-padding-small;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    height: 1em;
    font-size: 13px;
    color: $chips-delete-color;
    &:hover,
    &:focus {
      color: $chips-color;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 1em;
        width: 1em;
        border-radius: 1em;
        background-color: $white;
        opacity: 0.2;
      }
    }
    &:active {
      &::after {
        opacity: 0.3;
      }
    }
    [class^="ds-icon"],
    svg {
      vertical-align: top;
      font-size: 1em;
    }
  }
  &.ds-select-chips__more {
    margin-right: 0;
    padding-right: $chips-more-margin-right;
    text-align: center;
    button {
      position: static;
      padding: 0;
      top: auto;
      right: auto;
      transform: none;
    }
  }
}

.ds-select-chips__item--deletable {
  padding-right: $chips-padding-large;
}

.ds-select-chips__item--clickable {
  cursor: pointer;
}

.ds-select-chips__item--disabled {
  opacity: 0.5;
  button {
    cursor: default;
    &:hover,
    &:focus {
      color: $chips-color;
    }
  }
}

.ds-select-chips__expand {
  position: absolute;
  top: 4px;
  right: 0;
  width: 24px;
  height: 24px;
}

.ds-select-chips__item--primary {
  background-color: $chips-bg-primary;
  color: $chips-color-primary;
  button {
    color: $chips-delete-primary;
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.ds-select-chips__item--secondary {
  background-color: $chips-bg-secondary;
  color: $chips-color-secondary;
  button {
    color: $chips-delete-secondary;
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Chips
//
// Chips are items selected by a user from a long list by typing.
//
// Style guide: Chips

// Normal
//
// Chips component is a list of item (`.ds-select-chips__item`) in a container (`.ds-select-chips`). Use `.ds-select-chips__item--disabled` class to set an item disabled.
//
// Markup: ../../templates/partials/components/chips/chips.hbs
//
// Weight: -10
//
// Style guide: Chips.Normal
