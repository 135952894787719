.carousel > div > div > div > .toto {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}

.carousel {
  height: 400px;
}

.dotsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-top: -35px;
}
.dots {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.dotsSelected {
  background-color: white;
}
