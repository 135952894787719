.ds-pagination {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: $pagination-container-margin-topBottom 0;
  padding: 0;
  text-align: right;
  @include media-breakpoint-up(md) {
    [class^="ds-icon"] {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    [class^="ds-icon"] + a,
    [class^="ds-icon"] + span {
      display: none;
    }
  }
  ol {
    padding-left: 0;
    margin: 0;
  }
  a {
    display: block;
    padding: $pagination-link-padding-topBottom
      $pagination-link-padding-leftRight;
    text-decoration: none;
    &:hover,
    &:focus {
      background: $pagination-bg-hover;
    }
  }
}

.ds-pagination__item-previous,
.ds-pagination__item-next,
.ds-pagination__item-ellipsis,
.ds-pagination__item {
  display: inline-block;
  margin: 0 $pagination-item-margin-leftRight;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  &.ds-pagination__item--disabled {
    padding: $pagination-link-padding-topBottom
      $pagination-link-padding-leftRight;
    cursor: default;
    color: $pagination-color-disabled;
  }
}
.ds-pagination__item-ellipsis,
.ds-pagination__item {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.ds-pagination__item--current {
  padding: $pagination-link-padding-topBottom $pagination-link-padding-leftRight;
  color: $pagination-color-active;
  background: $pagination-bg-active;
  @include media-breakpoint-down(sm) {
    display: inline-block;
    color: $pagination-legend-color;
    background-color: $white;
    span:before {
      content: attr(data-label);
      margin-right: $pagination-item-margin-leftRight;
    }
  }
}

.ds-pagination__item-ellipsis {
  padding: $pagination-link-padding-topBottom $pagination-link-padding-leftRight;
}

.ds-pagination__legend {
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0;
  margin-top: $pagination-legend-margin-top;
  font-size: 12px;
  font-weight: 500;
  color: $pagination-legend-color;
  @include media-breakpoint-down(sm) {
    position: static;
    left: auto;
    justify-content: center;
    margin-bottom: $pagination-container-margin-topBottom;
  }
  .ds-form-group {
    display: inline-block;
    max-width: 65px;
    min-width: 45px;
    margin: -$pagination-legend-margin-top $pagination-form-group-margin-right 0
      0;
  }
  select {
    min-width: 60px;
  }
  label {
    margin-left: $pagination-form-group-margin-right;
  }
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Pagination
//
// Use pagination to allow navigation between pages that represent an ordered collection of items.
//
// Style guide: Pagination

// Normal
//
// Use a wrapping `<nav>` with a descriptive `aria-label` to reflects its purpose for assistive technologies.
// Don't use `a` tag for item that do not link to another page (`.ds-pagination__item--disabled`or `.ds-pagination__item--current`).
//
// Markup: ../../templates/partials/components/pagination/pagination.hbs
//
// Weight: -10
//
// Style guide: Pagination.Normal

// With legend
//
// Add a legend to your pagination with `.ds-pagination__legend` element.
//
// Markup: ../../templates/partials/components/pagination/pagination-legend.hbs
//
// Weight: -9
//
// Style guide: Pagination.Legend

// Entry selector
//
// Add a select element class in `.ds-pagination--legend` to filter the number of entry in a list or a table.
//
// Markup: ../../templates/partials/components/pagination/pagination-entry-selector.hbs
//
// Weight: -8
//
// Style guide: Pagination.EntrySelector

// Responsive Entry selector
//
// Responsive exemple for entry selector pagination
//
// Markup: ../../templates/partials/components/pagination/pagination-entry-selector-responsive.hbs
//
// Weight: -7
//
// Style guide: Pagination.EntrySelectorResponsive
