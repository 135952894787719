// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .ds-container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .ds-container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .ds-row {
    @include make-row();
    &.ds-row-grid {
      > [class*="ds-col-"] {
        margin-bottom: ($grid-gutter-width / 3) * 2;
      }
    }
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .ds-no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .ds-col,
    > [class*="ds-col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}

// -------------------------------------------------------------------
//
// !!! DO NOT DELETE COMMENTS BELOW !!!!
// Use to generate stylesheet documentation
//
// -------------------------------------------------------------------

// Grid
//
// Based on Boostrap grid, Airbus design system provides predefined classes to built responsive / 12-columns-based layout.
//
// Weight: -9
//
// Style guide: Grid

// Overview
//
// Grid is based on `flexbox` properties, flexbox grid is only suported by: the latest Chrome and Firefox, Safari 6+, IE 10+, iOS 7+, Android 4.4+.
//
// Here's how grid works:
// * Containers provide a means to center and horizontally pad your site’s contents. Use `.ds-container` for a responsive pixel width or `.ds-container-fluid` for `width: 100%` across all viewport and device sizes.
// * In a grid layout, content must be placed within columns and only columns may be immediate children of rows.
// * Column classes indicate the number of columns you’d like to use out of the possible 12 per row. So, if you want three equal-width columns across, you can use `.ds-col-4`.
// * Column widths are set in percentages, so they’re always fluid and sized relative to their parent element.
// * Use `ds-col-{breakpoint}-auto` classes to size columns based on the natural width of their content.
//
//
// |  | Extra small | Small | Medium | Large | Extra large |
// | --- | --- | --- |
// | **Max container width** | None (auto) | 540px | 720px | 960px | 1140px |
// | **Class prefix** | `.ds-col-` | `.ds-col-sm-` | `.ds-col-md-` | `.ds-col-lg-` | `.ds-col-xl-` |
//
// Markup: ../../templates/partials/base/grid/grid-overview.hbs
//
// Weight: -10
//
// Style guide: Grid.Overview

// Responsive
//
// Airbus Digital Design System is mobile-first. Code for small screens first, and larger devices will inherit those styles.
// Use a combination of different classes for each column to change layout depending screen width.
//
// Use `.ds-row-grid` on `.ds-row` to add a bottom margin on each block.
//
// Markup: ../../templates/partials/base/grid/grid-responsive.hbs
//
// Weight: -8
//
// Style guide: Grid.responsive

// Column alignment
//
// Columns in a flex grid can be aligned across the horizontal or vertical axis of their parent row.
//
// Style guide: Grid.Alignment

// Horizontal alignment
//
// By default, all columns align to the left, but this can be overridden with by adding the `.ds-justify-content-[dir]` class to the flex row
//
// Markup: ../../templates/partials/base/grid/grid-horizontal-alignment.hbs
//
// Style guide: Grid.Alignment.HorizontalAlignment

// Vertical alignment
//
// Your options for vertical alignment are top, middle and bottom.
// Applying a vertical alignment class to the flex row will affect every column directly inside it.
//
// Markup: ../../templates/partials/base/grid/grid-vertical-alignment.hbs
//
// Style guide: Grid.Alignment.VerticalAlignment

// Vertical alignment individually
//
// Vertical alignment classes can also be applied to individual columns.
//
// Markup: ../../templates/partials/base/grid/grid-vertical-alignment-itself.hbs
//
// Style guide: Grid.Alignment.VerticalAlignmentItself

// Reordering
//
// Style guide: Grid.Order

// Order classes
//
// Use `.ds-order-` classes for controlling the visual order of your content (`.ds-order-1`, `.ds-order-2`, `.ds-order-3`, … , `.ds-order-12`). If you want to put an element on the first or last position, use `.ds-order-first` or `.ds-order-last`.
//
// Markup: ../../templates/partials/base/grid/grid-order.hbs
//
// Style guide: Grid.Order.OrderClasses

// Offset classes
//
// Move columns to the right using `.ds-offset-md-*` classes. These classes increase the left margin of a column by `*` columns.
//
// Markup: ../../templates/partials/base/grid/grid-offset.hbs
//
// Style guide: Grid.Order.OffsetClasses

// No gutter
//
// Gutters can be removed with `.ds-no-gutters`. The class removes negative margins from nested row under collapsed parent.
//
// Markup: ../../templates/partials/base/grid/grid-collapse.hbs
//
// Style guide: Grid.NoGutter
