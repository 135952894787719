.body {
  padding: 50px 40px 150px 40px;
}

.body .aligner {
  text-align: end;
}

.entities {
  margin-top: 60px;
}

.entities .message {
  font-size: 18px;
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 5px;
}
