@import "../../lib/styles/base";
@import "../../../../horizon-stylesheets/src/scss/mixins/breakpoints";
@import "../../../../horizon-stylesheets/src/scss/utilities/common";
@import "../../../../horizon-stylesheets/src/scss/patterns/modal";

// This is a fix to a material-ui bug, to be removed when possible
.ds-modal--body-scroll,
.ds-modal--fullscreen {
  padding-right: 0 !important;
}
