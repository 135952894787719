.form {
  padding: 25px 40px 150px 40px;
}

.form .section {
  border: 1px solid #e0e3e9;
  margin-top: 40px;
  padding: 20px;
}

.form .section .divider {
  height: 1px;
  width: 100%;
  background-color: #e0e3e9;
  margin-bottom: 45px;
}

.form .section .title {
  margin-bottom: 20px;
}

.form .section .flowTypeLabel {
  color: #647592;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.form .section .flowTypeLabel span {
  color: #e4002b;
}

.form .section select {
  width: 100%;
}

.form .section .row svg {
  margin-left: 10px;
  fill: #9aa5b8;
}

.form .section .equipmentBox {
  border: 1px solid #e0e3e9;
  padding: 0 20px;
  margin-bottom: 20px;
}

.form .section .equipmentBox .equipmentHeader {
  display: flex;
  align-items: center;
  width: calc(100% + 40px);
  height: 40px;
  border-bottom: 1px solid #e0e3e9;
  padding: 0 20px;
  margin-left: -20px;
  margin-bottom: 20px;
}
