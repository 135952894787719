.sucessImage {
  height: 40vh;
  width: 100%;
  background-image: url("../../../statics/images/screening-success.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.successBody {
  display: flex;
  justify-content: center;
  padding: 50px 25px;
}

.successBody .shieldIcon {
  margin-right: 20px;
}

.messageBody {
  padding: 50px 50px 100px 50px;
}

.messageBody .header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.messageBody .licenses {
  max-width: 400px;
}

.messageBody .licenses .flag {
  margin-left: 10px;
}

.messageBody .results {
  margin-top: 50px;
}

.messageBody .results .subResults {
  padding: 25px 0 0 50px;
}

.arrow {
  display: flex;
  align-items: center;
}

.arrow svg {
  margin-top: -3px;
}

.row {
  display: flex;
  align-items: center;
}

.row .traficLight {
  width: 94px;
  margin-right: 30px;
}

@media screen and (max-width: 868px) {
  .sucessImage {
    display: none;
  }
}
.result {
  position: relative;
  padding: 10px 25px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  width: 100%;
}

.result .traficLightBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.result .traficLightBox.red {
  background-color: #ff6b6b;
}

.result .traficLightBox.yellow {
  background-color: #f7b740;
}
.result .traficLightBox.green {
  background-color: #1dd1a1;
}

.result .traficLight {
  width: 42px;
  margin-right: 25px;
}

.result .moreDetails {
  margin-right: 20px;
}

.title {
  display: flex;
  align-items: center;
}

.listname,
.regulation {
  font-size: 16px;
  margin-right: 15px;
}

.result .reasons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.result .reasons label {
  margin-right: 15px;
}

.result .badge {
  padding: 5px 15px;
  color: #ffffff;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
  background-color: #0085ad;
}
